import createPalette from "@mui/material/styles/createPalette";

export const palette = createPalette({
  text: {
    primary: "#414042",
  },
  primary: {
    main: "#4E53A3",
  },
  success: {
    main: "#4F8718",
  },
  warning: {
    main: "#FE630D",
  },
  error: {
    main: "#B02323",
  },
  background: {
    default: "#ffffff",
  },
  brandPurple: {
    dark: "#CBCDE6", // border highlight
    main: "#E7E8F3", // shading selected
    light: "#F3F3F9", // shading highlight
  },
  brandGray: {
    dark: "#C0C0C0", // disabled
    main: "#D4D4D4", // border default
    light: "#F1F2F2", // shading default
  },
});
