import { Typography } from "@mui/material";
import { Trans, useTranslation } from "react-i18next";
import { LogService } from "se-libcore/services";
import { LoadingSpinner } from "se-libcore/components";
import { GetLoginUrlResult } from "se-libcore/auth";
import { useIsAuthenticated } from "../../shared/useIsAuthenticated";
import BannerDevice from "../../images/bannerdevice.png";
import {
  Banner,
  Device,
  WelcomeText,
  WelcomeBannerText,
  WelcomeBannerContainer,
  SignInButton,
} from "./WelcomeBanner.styles";
import { useAuthResult } from "../../shared/lib/auth/hooks/useAuthResult";
import { useGetLoginUrl } from "../../shared/lib/auth/hooks/useGetLoginUrl";

const WelcomeBanner = () => {
  const { t } = useTranslation(undefined, { keyPrefix: "screen.welcomeHome" });
  const isAuthenticated = useIsAuthenticated();
  const { user } = useAuthResult();
  const { data, isLoading, error } = useGetLoginUrl();

  const loginRedirect = (data: GetLoginUrlResult) => {
    if (!data?.url) {
      LogService.error("[access-control] Invalid login url response");

      return;
    }

    try {
      window.location.href = data.url;
    } catch (err) {
      LogService.error("[access-control] Error while trying to open login url");
      LogService.error({ error: err });
    }
  };

  return (
    <Banner>
      <Device src={BannerDevice} alt="Device" />
      <WelcomeBannerContainer>
        <WelcomeBannerText>{t("welcomeBanner")}</WelcomeBannerText>
        {isAuthenticated && user ? (
          <WelcomeText>
            {t("signedIn")} {user.preferredUsername}.
          </WelcomeText>
        ) : (
          <>
            <WelcomeText>{t("signIn")}</WelcomeText>
            <SignInButton
              disabled={isLoading || !data?.url}
              onClick={() => {
                if (data) loginRedirect(data);
              }}
            >
              {isLoading ? (
                <LoadingSpinner fullscreen={false} />
              ) : (
                <Trans t={t} i18nKey="signInButton" parent="div" />
              )}
            </SignInButton>
            {!!error && (
              <>
                <Typography color="error">{t("cannotGetSigninUrl")}</Typography>
                <Typography color="error">
                  {error.getFormattedErrorMessage(t)}
                </Typography>
              </>
            )}
          </>
        )}
      </WelcomeBannerContainer>
    </Banner>
  );
};

export default WelcomeBanner;
