import { useAuthResult } from "../../auth/hooks/useAuthResult";
import { Permission } from "../permission.types";
import { verify } from "../verify";

export const usePermission = (permissionKey: Permission) => {
  const { user } = useAuthResult();

  if (!user?.permissions) return false;
  return verify(permissionKey, user?.permissions as Permission[]);
};
