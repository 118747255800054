import { Permission } from "../permission.types";
import { usePermission } from "../hooks/usePermission";
import { Disabled } from "./Disabled";

interface AccessGuardProps {
  children: JSX.Element;
  id: Permission;
}

const AccessGuard: (props: AccessGuardProps) => JSX.Element = ({
  children,
  id,
}) => {
  const allowed = usePermission(id);

  if (allowed) {
    return children;
  }

  return <Disabled>{children}</Disabled>;
};

export default AccessGuard;
