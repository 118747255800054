import styled from "styled-components";
import { Box, Card, CardContent, Button } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";

export const Container = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  justify-content: flex-start;
  padding: 1.25rem;
  width: 100%;
`;

export const CardsContainer = styled(Box)`
  counter-reset: step;
  display: flex;
  fle-direction: row;
  flex-wrap: wrap;
  gap: 1rem;
  justif-content: center;
  margin-top: 1rem;
  padding: 1rem;
`;

export const StyledCard = styled(Card)`
  background-color: #ffffff;
  border-radius: 0.25rem;
  border: 1px solid ${({ theme }) => theme.palette.brandGray.main};
  position: relative;
  width: 19.5rem;

  .MuiCardActionArea-root {
    align-items: center;
    display: flex;
    flex-direction: column;
    margin-top: -1px;
  }

  .MuiCardContent-root:last-child {
    color: ${({ theme }) => theme.palette.primary.main};
    padding-bottom: 1rem;
  }
`;

export const StyledCardContent = styled(CardContent)`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  padding: 2rem 2rem 1.75rem;
  text-align: center;

  .MuiTypography-h5 {
    margin-bottom: 0.5rem;
  }

  .MuiTypography-body2 {
    color: ${({ theme }) => theme.palette.primary.main};
  }
`;

export const NumberCircle = styled.div`
  align-items: center;
  background-color: #ffffff;
  border-radius: 50%;
  border: 1px solid ${({ theme }) => theme.palette.warning.main};
  color: ${({ theme }) => theme.palette.warning.main};
  display: flex;
  font-size: 2.25rem;
  font-weight: 700;
  height: 3.75rem;
  justify-content: center;
  left: 50%;
  position: absolute;
  right: 0.625rem;
  top: 3.75rem;
  transform: translateX(-50%);
  width: 3.75rem;
  z-index: 1;

  &::before {
    counter-increment: step;
    content: counter(step);
  }
`;

export const ButtonsContainer = styled.div`
  display: flex;
  font-size: 1rem;
  gap: 0.625rem;
  justify-content: center;
  margin-top: 1rem;
  white-space: nowrap;
`;

export const StoreButton = styled(Button)`
  background: transparent;
  border: none;
  height: auto;
  margin: 0;
  padding: 0;

  &:hover {
    background: transparent;
  }

  img {
    width: auto; 
    height: 2.375rem;
`;

export const InfoSectionContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
  gap: 1rem;
  color: ${({ theme }) => theme.palette.primary.main};
`;

export const InfoSection = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  width: 19.5rem;
`;

export const InfoIconImage = styled.img`
  width: 3.125rem;
  height: auto;
  margin-right: 1rem;
  margin-top: 0.5rem;
`;

export const Link = styled(RouterLink)`
  color: ${({ theme }) => theme.palette.primary.main};
  text-decoration: none;
`;
