import { useTranslation } from "react-i18next";
import { PageStyles } from "../../layout/Page.styles";

const { Header, Title, Body } = PageStyles;

const DevicesScreen = () => {
  const { t } = useTranslation(undefined, { keyPrefix: "screen.devices" });

  return (
    <>
      <Header>
        <Title>{t("title")}</Title>
      </Header>
      <Body>{t("bodyPlaceholder")}</Body>
    </>
  );
};

export default DevicesScreen;
