import { Trans, useTranslation } from "react-i18next";
import { Typography, CardActionArea, CardMedia, Button } from "@mui/material";
import {
  CardsContainer,
  StyledCard,
  StyledCardContent,
  NumberCircle,
  ButtonsContainer,
  InfoSectionContainer,
  InfoSection,
  InfoIconImage,
  Container,
  Link,
} from "./WelcomeHomeScreen.styles";
import Device from "../../images/device.png";
import InstallApp from "../../images/installtheapp.png";
import ConnectAndMeasure from "../../images/connectandmeasure.png";
import PurplFindout from "../../images/purplfindout.svg";
import HelpDesk from "../../images/helpdesk.svg";
import Shop from "../../images/shop.svg";
import WelcomeBanner from "./WelcomeBanner";
import appStoreQRCodeImg from "../../images/QRcodeiOS.png";
import googlePlayQRCodeImg from "../../images/QRcodeAndroid.png";
import AppDownloadButton from "./AppDownloadButton";

const AdminHomeScreen = () => {
  const { t } = useTranslation(undefined, { keyPrefix: "screen.welcomeHome" });

  return (
    <Container>
      <WelcomeBanner />
      <CardsContainer>
        <StyledCard sx={{ boxShadow: 0 }}>
          <NumberCircle />
          <CardActionArea disableRipple>
            <CardMedia component="img" height="100" image={Device} />
          </CardActionArea>
          <StyledCardContent>
            <Typography variant="h5">{t("getDevice")}</Typography>
            <Typography variant="body2" component="span">
              {t("getDeviceText")}
            </Typography>
            <ButtonsContainer>
              <Button
                onClick={() => window.open("https://getpurpl.com/", "_blank")}
              >
                {t("shopNow")}
              </Button>
              <Button
                variant="outlined"
                onClick={() =>
                  window.open(
                    "https://www.purplscientific.com/where-to-buy/",
                    "_blank"
                  )
                }
              >
                {t("showDistributers")}
              </Button>
            </ButtonsContainer>
          </StyledCardContent>
        </StyledCard>
        <StyledCard sx={{ boxShadow: 0 }}>
          <NumberCircle />
          <CardActionArea disableRipple>
            <CardMedia component="img" height="100" image={InstallApp} />
          </CardActionArea>
          <StyledCardContent>
            <Typography gutterBottom variant="h5" component="div">
              {t("installApp")}
            </Typography>
            <Typography variant="body2" component="span">
              {t("installAppText")}
            </Typography>
            <ButtonsContainer>
              <AppDownloadButton
                source="appStore"
                qrCodeImg={appStoreQRCodeImg}
                url="https://apps.apple.com/us/app/purpl-pro-2/id6479393669"
                altText={t("downloadAppStore")}
              />
              <AppDownloadButton
                source="googlePlay"
                qrCodeImg={googlePlayQRCodeImg}
                url="https://play.google.com/store/apps/details?id=com.purplscan.app"
                altText={t("downloadPlayStore")}
              />
            </ButtonsContainer>
          </StyledCardContent>
        </StyledCard>
        <StyledCard sx={{ boxShadow: 0 }}>
          <NumberCircle />
          <CardActionArea disableRipple>
            <CardMedia component="img" height="100" image={ConnectAndMeasure} />
          </CardActionArea>
          <StyledCardContent>
            <Typography gutterBottom variant="h5" component="div">
              {t("connectAndMeasure")}
            </Typography>
            <Typography variant="body2" component="span">
              {t("connectAndMeasureText")}
            </Typography>
          </StyledCardContent>
        </StyledCard>
      </CardsContainer>
      <InfoSectionContainer>
        <InfoSection>
          <InfoIconImage src={PurplFindout} alt={t("moreAboutPurpl") || ""} />
          <Typography component="span">
            <Trans t={t} i18nKey="findoutMore" parent="div" />
            <Link to="https://www.purplscientific.com/" target="_blank">
              <Trans t={t} i18nKey="findoutMoreLink" parent="div" />
            </Link>
          </Typography>
        </InfoSection>
        <InfoSection>
          <InfoIconImage src={HelpDesk} alt={t("helpDeskAlt") || ""} />
          <Typography component="span">
            <Trans t={t} i18nKey="helpDesk" parent="div" />
            <Link
              to="https://purplscientific.freshdesk.com/support/home"
              target="_blank"
            >
              <Trans t={t} i18nKey="helpDeskLink" parent="div" />
            </Link>
          </Typography>
        </InfoSection>
        <InfoSection>
          <InfoIconImage src={Shop} alt={t("purplShopAlt") || ""} />
          <Typography component="span">
            <Trans t={t} i18nKey="purplShop" parent="div" />
            <Link to="https://getpurpl.com/" target="_blank">
              <Trans t={t} i18nKey="purplShopLink" parent="div" />
            </Link>
          </Typography>
        </InfoSection>
      </InfoSectionContainer>
    </Container>
  );
};

export default AdminHomeScreen;
