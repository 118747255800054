import { Trans, useTranslation } from "react-i18next";
import styled from "styled-components";
import { MenuItem } from "@mui/material";
import { PageStyles } from "../../layout/Page.styles";
import UserTable from "./UserTable/UserTable";
import SearchField from "../../shared/components/SearchField/SearchField";
import { UserDataRow } from "./UserTable/UserTable.types";
import { useSelection } from "../../hooks/useSelection";
import DropdownMenu from "../../shared/components/DropdownMenu";
import ConfirmationModal from "../../shared/components/ConfirmationModal/ConfirmationModal";
import DropdownButton from "../../shared/components/DropdownButton";
import SelectedItemsIndicator from "../../shared/components/SelectedItemsIndicator";

const { Header, Title, Body } = PageStyles;

const Container = styled.div`
  display: flex;
  gap: 0.625rem;
  position: relative;
`;

const StyledDropdownMenu = styled(DropdownMenu)`
  position: absolute;
`;

// TODO: change the green color to hex color
const GreenOutlinedButton = styled(DropdownButton)`
  && {
    border-color: green;
    color: green;

    .MuiButton-outlined {
      color: green;
    }

    svg {
      color: green;
    }
  }
`;

const UsersScreen = () => {
  const { t } = useTranslation();

  const {
    selectedItems,
    handleCheckboxChange,
    handleAction,
    handleDeleteConfirm,
    handleModalCancel,
    isConfirmModalOpen,
    handleClearSelection,
    tableKey,
  } = useSelection<UserDataRow>();

  const actions = [
    "forcePasswordReset",
    "reactivateUsers",
    "deactivateUsers",
    "delete",
    "clearSelection",
  ];

  return (
    <>
      <Header>
        <Title>{t("screen.users.title")}</Title>

        <SearchField placeholder={t("screen.users.searchByEmail")} />
      </Header>
      <Body>
        <Container>
          {selectedItems.length > 0 && (
            <>
              <SelectedItemsIndicator
                count={selectedItems.length}
                selectedTextKey="usersSelected"
                translationPrefix="screen.users"
                width="130px"
                arrowPosition="20%"
              />
              {/* Only render the DropdownMenu when there are selected users */}

              <StyledDropdownMenu
                button={
                  <GreenOutlinedButton variant="outlined">
                    {t("screen.users.action")}
                  </GreenOutlinedButton>
                }
              >
                {actions.map((action, index) =>
                  action === "clearSelection" ? (
                    <MenuItem key={index} onClick={handleClearSelection}>
                      {t("screen.users.clearSelection")}
                    </MenuItem>
                  ) : (
                    <MenuItem key={index} onClick={() => handleAction(action)}>
                      {t(`screen.users.${action}`)}
                    </MenuItem>
                  )
                )}
              </StyledDropdownMenu>
            </>
          )}
        </Container>
        <UserTable
          key={tableKey}
          onCheckboxChange={handleCheckboxChange}
          selectedItems={selectedItems}
        />
      </Body>
      <ConfirmationModal
        open={isConfirmModalOpen}
        onConfirm={handleDeleteConfirm}
        onCancel={handleModalCancel}
        title={t("modalConfirmation.deleteConfirmation.deleteUsersTitle")}
        message={
          <Trans
            t={t}
            i18nKey="modalConfirmation.deleteConfirmation.deleteUsersMessage"
          />
        }
        confirmButton={t("modalConfirmation.deleteConfirmation.confirmButton")}
        cancelButton={t("modalConfirmation.deleteConfirmation.cancelButton")}
      />
    </>
  );
};

export default UsersScreen;
