import { useCallback, useEffect, useState } from "react";
import { Button, Grid, Typography } from "@mui/material";
import { LoadingSpinner } from "se-libcore/components";
import { useTranslation } from "react-i18next";
import { UsersDataSchema } from "se-libcore/users";
import { FilterFactory, SortDirection } from "se-libcore/filters";
import { useAtom } from "jotai";
import { atomWithStorage, RESET } from "jotai/utils";

import { userSortingAtoms } from "./UsersDetailsFilter.atom";
import Table from "../../../shared/components/Table/Table";
import { UserDataRow } from "./UserTable.types";
import UserDesktopHeader from "./UserDesktopHeader";
import UserDesktopRow from "./UserDesktopRow";
import UserMobileRow from "./UserMobileRow";
import { useUsers } from "../../../hooks/useUsers";

interface UserTableProps {
  onCheckboxChange: (data: UserDataRow, isChecked: boolean) => void;
  selectedItems: UserDataRow[];
}

const pageAtom = atomWithStorage("usersPage", 0);
const rowsPerPageAtom = atomWithStorage("usersRowsPerPage", 20);

const UserTable = ({ onCheckboxChange, selectedItems }: UserTableProps) => {
  const [page, setPage] = useAtom(pageAtom);
  const [rowsPerPage, setRowsPerPage] = useAtom(rowsPerPageAtom);
  const [pagination, setPagination] = useState({});
  const [sortBy, setSortBy] = useAtom(userSortingAtoms.userSortBy);
  const [sortDirection, setSortDirection] = useAtom(
    userSortingAtoms.userSortDirection
  );

  const handleClickResetSearch = useCallback(() => {
    setSortBy(RESET);
    setSortDirection(RESET);
  }, [setSortBy, setSortDirection]);

  const { t } = useTranslation(undefined, { keyPrefix: "screen.users" });

  const onPageChange = (_: React.MouseEvent | null, page: number) => {
    setPage(page);
  };
  const onRowsPerPageChange = (e: React.ChangeEvent) => {
    // eslint-disable-next-line
    // @ts-ignore
    const newValue = e.target.value;
    setRowsPerPage(newValue);
    setPage(0);
    setPagination({ ...pagination, rowsPerPage: newValue });
  };

  const filterFactory = FilterFactory()
    .reset()
    .setPage(page)
    .setPerPage(rowsPerPage);
  // TODO: Handle filter factory creation when there are more filter criteria
  // .appendMetadataFilter({
  //   type: "STRING_FILTER",
  //   value: usersName,
  //   path: "name",
  // }
  // );

  if (sortBy.path !== "") {
    filterFactory.appendSortBy(sortBy).setSortOrder(sortDirection);
  }
  const handleSort = (sortBy: string, sortOrder: SortDirection) => {
    const prefix = ["active", "userEmail", "roles"].includes(sortBy)
      ? "METADATA"
      : "BASE";

    setSortBy({ prefix, path: sortBy });
    setSortDirection(sortOrder);
  };
  const { data, status, error } = useUsers(filterFactory);
  const users = data?.entities;

  const mapUsers = (users: UsersDataSchema[]): UserDataRow[] => {
    return users.map((user) => ({
      key: user.username,
      createdOn: user.createdOn,
      modifiedOn: user.modifiedOn,
      // partial id are the last 6 characters of username
      partialId: user.username.substring(user.username.length - 6),
      preferredUsername: user.preferredUsername,
      state: user.metadata?.active,
      role: user.metadata?.roles[0] || "",
    }));
  };

  // Both row sets contain the same user data, but presentation is different
  const desktopRows: JSX.Element[] = [];
  const mobileRows: JSX.Element[] = [];

  useEffect(() => {
    // MUI Table pagination
    // https://mui.com/material-ui/api/table-pagination/#TablePagination-prop-onRowsPerPageChange
    setPagination({
      count: data?.total,
      page,
      rowsPerPage,
      showFirstButton: true,
      showLastButton: true,
      onPageChange,
      onRowsPerPageChange,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.total, page, rowsPerPage]);

  if (users) {
    const mappedUsers = mapUsers(users as unknown as UsersDataSchema[]);

    //    mappedUsers.forEach((user, index: number) => {
    for (const user of mappedUsers) {
      desktopRows.push(
        <UserDesktopRow
          data={user}
          key={user.key}
          onCheckboxChange={onCheckboxChange}
        />
      );
      mobileRows.push(<UserMobileRow data={user} key={user.key} />);
    }
  }

  return (
    <>
      {status === "loading" && <LoadingSpinner />}
      {status === "success" && (
        <Table
          desktopHeader={
            <UserDesktopHeader
              hasSelectedRows={selectedItems.length > 0}
              onSort={handleSort}
            />
          }
          desktopRows={desktopRows}
          mobileRows={mobileRows}
          pagination={pagination}
          loading={false}
        />
      )}
      {!!error && (
        <Grid container spacing={0} direction="column" alignItems="center">
          <Typography align="center" variant="body1" marginBottom="10px">
            {error.getFormattedErrorMessage(t)}
          </Typography>
          <Button onClick={handleClickResetSearch}>
            {t("errors.resetSearch")}
          </Button>
        </Grid>
      )}
    </>
  );
};

export default UserTable;
