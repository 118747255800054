import { atom } from "jotai";
import { atomWithReset } from "jotai/utils";
import { SortDirection, SortBy } from "se-libcore/filters";

export const usersFilterAtoms = {
  usersName: atom(""),
};

export const userSortingAtoms = {
  // default sorting is by time
  userSortBy: atomWithReset<SortBy>({
    prefix: "BASE",
    path: "preferredUsername",
  }),
  userSortDirection: atomWithReset<SortDirection>(SortDirection.ASC),
};
