import { useState } from "react";
import { useTranslation, Trans } from "react-i18next";
import {
  Dialog,
  Button,
  Radio,
  RadioGroup,
  FormControlLabel,
  TextField,
} from "@mui/material";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { useAtom } from "jotai";
import { UserAtoms } from "./User.atom";
import { UserDetailsStyles } from "./UserDetails.styles";
import { Product } from "./UserTable.types";

const { StyledDialogTitle, StyledDialogActions, StyledDialogContent } =
  UserDetailsStyles;

type TrialDialogTypes = "Start" | "Stop" | "Change" | null;

interface TrialDialogProps {
  open: boolean;
  handleClose: () => void;
  showDialogType: TrialDialogTypes;
  selectedProduct: Product | null;
}

const TrialDialog = ({
  open,
  handleClose,
  showDialogType,
  selectedProduct,
}: TrialDialogProps) => {
  const { t } = useTranslation(undefined, {
    keyPrefix: "screen.users.trialDialog",
  });
  const [selectedUserData] = useAtom(UserAtoms.selectedUser);
  const [durationRadio, setDurationRadio] = useState("days");

  const { Detail, Label, Value } = UserDetailsStyles;

  const dialogDetails = [
    {
      label: "currentPeriod",
      value: <Trans t={t} i18nKey="daysRemaining" values={{ days: 99 }} />,
    },
    {
      label: "product",
      value: selectedProduct?.name,
    },
    {
      label: "user",
      value: selectedUserData?.preferredUsername,
    },
    {
      label: "role",
      value: selectedUserData?.role,
    },
  ];

  const handleApply = () => {
    // eslint-disable-next-line no-alert
    alert(`Perform action: ${showDialogType} subscription`);
  };

  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDurationRadio((event?.target as HTMLInputElement).value);
  };

  const renderTypeSpecificFields = () => {
    return (
      <>
        <Detail style={{ alignItems: "start" }}>
          <Label>
            {showDialogType === "Start" ? t("duration") : t("newDuration")}
          </Label>
          <Value>
            <RadioGroup
              aria-labelledby="demo-error-radios"
              name="quiz"
              value={durationRadio}
              onChange={handleRadioChange}
            >
              <FormControlLabel
                value="days"
                control={
                  <Radio style={{ paddingBottom: "0", paddingTop: "0" }} />
                }
                label={t("numberOfDays")}
              />
              <FormControlLabel
                value="date"
                control={<Radio />}
                label={t("untilDate")}
              />
            </RadioGroup>
          </Value>
        </Detail>
        {durationRadio === "days" && (
          <Detail>
            <Label>{t("days")}</Label>
            <Value>
              <TextField
                type="number"
                id="outlined-basic"
                variant="outlined"
                inputProps={{
                  min: 0,
                  max: 9999,
                }}
                placeholder={t("numberOfDays") as string}
              />
            </Value>
          </Detail>
        )}
        {durationRadio === "date" && (
          <Detail>
            <Label>{t("endDate")}</Label>
            <Value>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DemoContainer components={["DatePicker"]}>
                  <DatePicker label="Basic date picker" />
                </DemoContainer>
              </LocalizationProvider>
            </Value>
          </Detail>
        )}
      </>
    );
  };

  return (
    <Dialog fullWidth maxWidth="tablet" open={open} onClose={handleClose}>
      <StyledDialogTitle>
        {t(`${showDialogType?.toLowerCase()}ProductTrial`)}
      </StyledDialogTitle>
      <StyledDialogContent>
        {showDialogType === "Stop" && t("stopProductTrialText")}

        {dialogDetails.map((detail, index) => (
          <Detail key={index}>
            <Label>{t(detail.label)}</Label>
            <Value>{detail.value}</Value>
          </Detail>
        ))}

        {(showDialogType === "Start" || showDialogType === "Change") && (
          <>{renderTypeSpecificFields()}</>
        )}
      </StyledDialogContent>
      <StyledDialogActions>
        <Button variant="outlined" onClick={handleClose}>
          {t("cancel")}
        </Button>
        <Button onClick={handleApply} disabled>
          {t(`${showDialogType?.toLowerCase()}`)}
        </Button>
      </StyledDialogActions>
    </Dialog>
  );
};

export { TrialDialog };
export type { TrialDialogTypes };
