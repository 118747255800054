import { ThemeOptions } from "@mui/material";

// Use pixel units
export const breakpoints: ThemeOptions["breakpoints"] = {
  values: {
    mobileSmall: 0,
    mobileLarge: 360,
    tablet: 600,
    laptop: 1024,
    desktop: 1200,
  },
};

export default breakpoints;
