import { useTranslation } from "react-i18next";
import { Checkbox } from "@mui/material";
import { UnfoldMore, ArrowDownward, ArrowUpward } from "@mui/icons-material";
import { useAtom } from "jotai";
import { SortDirection } from "se-libcore/filters";
import { userSortingAtoms } from "./UsersDetailsFilter.atom";
import { TableDesktopStyles } from "../../../shared/components/Table/TableDesktop.styles";

const { Tr, Th, SortIconContainer, SortableTh, SortableDiv } =
  TableDesktopStyles;

interface UserDesktopHeaderProps {
  hasSelectedRows: boolean;
  onSort: (sortBy: string, sortOrder: SortDirection) => void;
}

const UserDesktopHeader = ({
  hasSelectedRows,
  onSort,
}: UserDesktopHeaderProps) => {
  const { t } = useTranslation(undefined, {
    keyPrefix: "screen.users.table",
  });

  type Column = {
    label: string;
    name: string;
  };

  const columns: Column[] = [
    { label: "userId", name: "preferredUsername" },
    { label: "partialId", name: "username" },
    { label: "role", name: "roles" },
    { label: "state", name: "active" },
    { label: "createdOn", name: "createdOn" },
    { label: "modifiedOn", name: "modifiedOn" },
  ];

  const [sortedColumn, setSortedColumn] = useAtom(userSortingAtoms.userSortBy);
  const [sortDirection, setSortDirection] = useAtom(
    userSortingAtoms.userSortDirection
  );

  const handleSort = (column: string) => {
    const newDirection =
      sortedColumn.path === column && sortDirection === SortDirection.ASC
        ? SortDirection.DSC
        : SortDirection.ASC;

    const prefix = ["active", "userEmail", "roles"].includes(sortedColumn.path)
      ? "METADATA"
      : "BASE";

    setSortedColumn({ ...sortedColumn, path: column, prefix });
    setSortDirection(newDirection);

    onSort(column, newDirection);
  };

  const renderSortIcon = (columnName: string) => (
    <SortIconContainer>
      {
        // eslint-disable-next-line no-nested-ternary
        sortedColumn.path !== columnName ? (
          <UnfoldMore className="defaultSortIcon" />
        ) : sortDirection === SortDirection.DSC ? (
          <ArrowDownward fontSize="small" />
        ) : (
          <ArrowUpward fontSize="small" />
        )
      }
    </SortIconContainer>
  );

  return (
    <Tr>
      <Th shrink padding="checkbox">
        <Checkbox indeterminate={hasSelectedRows} />
      </Th>
      {columns.map(({ label, name }, index) => (
        <SortableTh key={index} onClick={() => handleSort(name)}>
          <SortableDiv>
            {label !== "" && (
              <>
                {t(label)} {renderSortIcon(name)}
              </>
            )}
          </SortableDiv>
        </SortableTh>
      ))}
      <Th shrink />
    </Tr>
  );
};

export default UserDesktopHeader;
