import { Grid, Box } from "@mui/material";
import styled from "styled-components";

const Detail = styled.div`
  display: flex;
  align-items: center;
  margin-top: 1rem;
  overflow: hidden;
`;

const Label = styled.span`
  flex-shrink: 0;
  margin-right: 5px;
  min-width: 5rem;
  font-weight: bold;
`;

const Value = styled.span`
  flex-grow: 1;
`;

const GridItemRight = styled(Grid)`
  padding-left: 10px;
`;

const GridItemLeft = styled(Grid)`
  padding-right: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Rectangle = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 300px;
  height: 160px;
  background: ${({ theme }) => theme.palette.primary.main}15;
  color: ${({ theme }) => theme.palette.primary.main};
  /* Remove the bottom border for the last row */
  & > div:nth-child(n + 3) {
    border-bottom: none;
  }

  /* Remove the right border for the last square of each row */
  & > div:nth-child(2n) {
    border-right: none;
  }
  ${({ theme }) => theme.breakpoints.down("tablet")} {
    width: 100%;
  }
`;

const Square = styled.div`
  width: 50%;
  height: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-right: 2px solid ${({ theme }) => theme.palette.brandPurple.main};
  border-bottom: 2px solid ${({ theme }) => theme.palette.brandPurple.main};
`;

const Result = styled.span`
  font-size: 1.5rem;
  font-weight: bold;
`;

const DialogActionsContainer = styled(Box)`
  display: flex;
  justify-content: space-between;
  padding: 10px 20px;
`;

export const MeasurementDetailsStyles = {
  Detail,
  Label,
  Value,
  GridItemLeft,
  GridItemRight,
  Rectangle,
  Square,
  Result,
  DialogActionsContainer,
};
