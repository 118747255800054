import { useTranslation } from "react-i18next";
import { styled } from "@mui/material/styles";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import AppStoreIcon from "../../images/appstore.svg";
import GooglePlayIcon from "../../images/google-play.svg";

interface StoreButtonWithTooltipProps {
  source: "appStore" | "googlePlay";
  qrCodeImg: string;
  url: string;
  altText: string;
}

const CustomTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))`
  & .MuiTooltip-tooltip {
    background-color: #ffffff;
    padding: 8px;
    border: 1px solid #ccc;
  }
`;

const CustomIconButton = styled(IconButton)({
  "&:hover": {
    backgroundColor: "transparent",
  },
});

const AppDownloadButton = ({
  source,
  qrCodeImg,
  url,
  altText,
}: StoreButtonWithTooltipProps) => {
  const { t } = useTranslation(undefined, { keyPrefix: "screen.welcomeHome" });
  return (
    <CustomTooltip
      title={
        <img
          src={qrCodeImg}
          alt={t("qrCodeAlt") || ""}
          style={{ maxWidth: 150 }}
        />
      }
    >
      <CustomIconButton onClick={() => window.open(url, "_blank")}>
        <img
          src={source === "appStore" ? AppStoreIcon : GooglePlayIcon}
          alt={altText || ""}
        />
      </CustomIconButton>
    </CustomTooltip>
  );
};

export default AppDownloadButton;
