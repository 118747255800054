import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { api } from "se-libcore/api";
import { useAsyncQuery } from "se-libcore/async";
import { loginRedirectUri } from "se-libcore/auth";
import { LogService } from "se-libcore/services";
import { azureB2CClient } from "../../http/client";

export const useGetLoginUrl = () => {
  const { t } = useTranslation();
  const queryResult = useAsyncQuery({
    queryKey: ["access-control/login/oidc", loginRedirectUri],
    queryFn: () => api.auth.getLoginUrl(azureB2CClient, loginRedirectUri),
  });
  const { error } = queryResult;

  useEffect(() => {
    if (error) {
      LogService.error(
        "[access-control] Error while getting login url",
        error.getFormattedErrorMessage(t)
      );
    }
  }, [error, t]);

  return queryResult;
};
