import AppHeader from "./AppHeader";
import { AppViewStyles } from "./AppView.styles";

const { Main } = AppViewStyles;

const View: (props: { children: JSX.Element }) => JSX.Element = ({
  children,
}) => {
  return (
    <>
      <AppHeader />
      <Main>{children}</Main>
    </>
  );
};

export default View;
