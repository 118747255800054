import Empty from "./Empty";

const Percentage: (props: {
  value: number | string | undefined | null;
}) => JSX.Element = ({ value }) => {
  if (typeof value === "string") {
    return `${value}` as unknown as JSX.Element;
  }

  if (value !== undefined && value !== null && !Number.isNaN(value)) {
    const formattedValue = value.toFixed(1);
    return `${formattedValue}%` as unknown as JSX.Element;
  }

  return <Empty />;
};

export default Percentage;
