import { useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { MeasurementDataRow } from "./MeasurementTable.types";
import { TableMobileStyles } from "../../../shared/components/Table/TableMobile.styles";
import MeasurementDetails from "./MeasurementDetails";
import {
  DateTime,
  Decimal,
  Percentage,
} from "../../../shared/components/Formats";
import { useIsUserAdmin } from "../../../shared/useIsUserAdmin";

const { Arrow, Cell, Key, NestedRow, RowButton, Value } = TableMobileStyles;

const MeasurementMobileRow: (props: {
  data: MeasurementDataRow;
}) => JSX.Element = ({ data }) => {
  const { t } = useTranslation(undefined, {
    keyPrefix: "screen.measurements.table",
  });

  const [openModal, setOpenModal] = useState(false);
  const isUserAdministrator = useIsUserAdmin();

  return (
    <>
      <MeasurementDetails
        data={data}
        isOpen={openModal}
        onClose={() => setOpenModal(false)}
        title={isUserAdministrator ? data.email : data.name}
      />

      <RowButton onClick={() => setOpenModal(true)}>
        <Cell expand>
          <Key>{isUserAdministrator ? data.email : data.name}</Key>
          <div>
            {data.app} &mdash; <DateTime value={data.time} />
          </div>
          <div>
            <Trans
              t={t}
              i18nKey="deviceLabel"
              values={{ deviceId: data.deviceId }}
            />
          </div>
        </Cell>
        <Value>
          <NestedRow>
            <Key>{t("thc")}</Key>
            <Value>
              <Percentage value={data.thc} />
            </Value>
          </NestedRow>
          <NestedRow>
            <Key>{t("cbd")}</Key>
            <Value>
              <Percentage value={data.cbd} />
            </Value>
          </NestedRow>
          <NestedRow>
            <Key>{t("moisture")}</Key>
            <Value>
              <Percentage value={data.moisture} />
            </Value>
          </NestedRow>
          <NestedRow>
            <Key>{t("waterActivity")}</Key>
            <Value>
              <Decimal value={data.waterActivity} />
            </Value>
          </NestedRow>
        </Value>
        <Arrow />
      </RowButton>
    </>
  );
};

export default MeasurementMobileRow;
