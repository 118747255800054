import styled from "styled-components";
import { Link, Typography } from "@mui/material";

export const FooterContainer = styled.footer`
  align-items: center;
  border-top: 1px solid ${({ theme }) => theme.palette.brandGray.main};
  display: flex;
  height: 4.5rem;
  justify-content: space-between;
  margin-top: auto;
  padding: 1.5rem 2rem;
  width: 100%;
`;

export const FooterContent = styled.div`
  align-items: center;
  color: ${({ theme }) => theme.palette.text.primary};
  display: flex;
`;

export const StyledLink = styled(Link)`
  color: ${({ theme }) => theme.palette.text.primary};
  cursor: pointer;
  margin-left: 1rem;
  text-decoration: none;
`;

export const VersionText = styled(Typography)`
  margin-left: 1rem;
  text-decoration: none;
`;
