import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";

import { MenuItem } from "@mui/material";
import { MoreHoriz } from "@mui/icons-material";
import { useAtom } from "jotai";
import { RESET } from "jotai/utils";
import { UserAtoms } from "./User.atom";
import DropdownMenu from "../../../shared/components/DropdownMenu";
import { TableDesktopStyles } from "../../../shared/components/Table/TableDesktop.styles";
import { useProducts } from "../../../hooks/useProducts";
import { TrialDialog, TrialDialogTypes } from "./TrialDialog";

import Table from "../../../shared/components/Table/Table";
import { Product } from "./UserTable.types";

const { Tr, Th, Td, Buttons, Button } = TableDesktopStyles;

const UserProductsTable = (): JSX.Element => {
  const { t } = useTranslation(undefined, {
    keyPrefix: "screen.users",
  });

  const [showTrialDialog, setShowTrialDialog] = useState(false);
  const [showTrialDialogType, setShowTrialDialogType] =
    useState<TrialDialogTypes>(null);
  const [selectedProduct, setSelectedProduct] = useState<Product | null>(null);
  const [, setSelectedUserData] = useAtom(UserAtoms.selectedUser);

  const { status, data: products } = useProducts();

  const createHeader = () => {
    return (
      <Tr>
        <Th>{t("products.name")}</Th>
        <Th>{t("products.status")}</Th>
        <Th>{t("products.details")}</Th>
        <Th />
      </Tr>
    );
  };

  const handleTrialModalClick = (type: TrialDialogTypes, product: Product) => {
    setShowTrialDialogType(type);
    setShowTrialDialog(true);
    setSelectedProduct(product);
  };

  const handleClose = () => {
    setSelectedUserData(RESET);
    setShowTrialDialog(false);
  };

  const createDesktopRows = (): JSX.Element => {
    return (
      // eslint-disable-next-line react/jsx-no-useless-fragment
      <>
        {products?.entities.map((product) => (
          <Tr key={product.id}>
            <Td title={product.summary} style={{ fontWeight: "bold" }}>
              {product.name}
            </Td>
            <Td />
            <Td />
            <Td shrink padding="none">
              <Buttons>
                <DropdownMenu
                  button={
                    <Button>
                      <MoreHoriz />
                    </Button>
                  }
                >
                  <MenuItem
                    onClick={() => handleTrialModalClick("Start", product)}
                  >
                    {t("products.startTrial")}
                  </MenuItem>
                  <MenuItem
                    onClick={() => handleTrialModalClick("Stop", product)}
                  >
                    {t("products.stopTrial")}
                  </MenuItem>
                  <MenuItem
                    onClick={() => handleTrialModalClick("Change", product)}
                  >
                    {t("products.changeTrialPeriod")}
                  </MenuItem>
                </DropdownMenu>
              </Buttons>
            </Td>
          </Tr>
        )) ?? []}
      </>
    );
  };

  return (
    <>
      {status === "loading" && (
        <Box display="flex" justifyContent="center">
          <CircularProgress size="1.25rem" />
        </Box>
      )}
      <TrialDialog
        open={showTrialDialog}
        handleClose={handleClose}
        showDialogType={showTrialDialogType}
        selectedProduct={selectedProduct}
      />
      {status === "success" && products && (
        <Table
          desktopHeader={createHeader()}
          desktopRows={createDesktopRows()}
          mobileRows={<div>hi</div>}
          loading={false}
        />
      )}
    </>
  );
};

export default UserProductsTable;
