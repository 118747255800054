import styled from "styled-components";
import { Typography } from "@mui/material";
import { visuallyHidden } from "@mui/utils";
import { ReactComponent as PurplProPortalLogo } from "../images/purpl_pro_portal_logo.svg";

const Container = styled.header`
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  z-index: 3;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
  background: ${({ theme }) => theme.palette.primary.dark};
  color: ${({ theme }) => theme.palette.background.default};

  ${({ theme }) => theme.breakpoints.up("laptop")} {
    position: relative;
  }
`;

const Logo = styled(PurplProPortalLogo).attrs({ role: "presentation" })`
  display: block;
  width: 102px;
  box-sizing: content-box;
  padding: 0 0.25rem;

  ${({ theme }) => theme.breakpoints.up("laptop")} {
    width: 142px;
    padding: 0.5rem 1rem;
  }
`;

const Name = styled.span.attrs({ style: visuallyHidden })``;

const Title = styled(Typography).attrs({ variant: "h1" })``;

export const AppHeaderStyles = {
  Container,
  Logo,
  Name,
  Title,
};
