import { useState } from "react";
import {
  Button,
  MenuItem,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from "@mui/material";
import { Trans, useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import AccessGuard from "../../../shared/lib/permissions/components/AccessGuard";
import DropdownMenu from "../../../shared/components/DropdownMenu";
import DropdownButton from "../../../shared/components/DropdownButton";
import { MeasurementDataRow } from "./MeasurementTable.types";
import { MeasurementDetailsStyles } from "./MeasurementDetails.styles";
import { PurpleDeleteMeasurementKey } from "../../../shared/lib/permissions/keys";
import { useDeleteMeasurement } from "../../../hooks/useDeleteMeasurement";
import ConfirmationModal from "../../../shared/components/ConfirmationModal/ConfirmationModal";

const {
  Detail,
  Label,
  Value,
  GridItemLeft,
  GridItemRight,
  /* Title, */
  Rectangle,
  Square,
  Result,
  DialogActionsContainer,
} = MeasurementDetailsStyles;

interface MeasurementDetailProps {
  isOpen: boolean;
  onClose: () => void;
  data: MeasurementDataRow;
  title: string;
}

const MeasurementDetails: (props: MeasurementDetailProps) => JSX.Element = ({
  isOpen,
  onClose,
  data,
  title,
}) => {
  const { mutate: deleteMeasurement } = useDeleteMeasurement();
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const { t } = useTranslation();

  const navigate = useNavigate();

  const handleDeleteConfirmation = async () => {
    const params = {
      measurementId: data.key.replace("measurement-", ""),
      username: data.email,
    };

    await deleteMeasurement(params);
    navigate("/measurements");
  };

  const measurementDetails = [
    { label: "user", value: data.email },
    { label: "time", value: data.time },
    { label: "app", value: data.app },
    { label: "device", value: data.deviceId },
  ];

  const results = [
    { title: "thc", result: `${data.thc}` },
    { title: "cbd", result: `${data.cbd}` },
    { title: "moisture", result: `${data.moisture}` },
    { title: "waterActivity", result: data.waterActivity },
  ];

  return (
    <>
      <Dialog fullWidth maxWidth="tablet" open={isOpen} onClose={onClose}>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <GridItemLeft item mobileSmall={12} tablet={6}>
              <Rectangle>
                {results.map((result, index) => (
                  <Square key={index}>
                    <Typography>
                      {t(
                        `screen.measurements.measurementDetails.${result.title}`
                      )}
                    </Typography>
                    <Result>{result.result}</Result>
                  </Square>
                ))}
              </Rectangle>
            </GridItemLeft>
            <GridItemRight item mobileSmall={12} tablet={6}>
              {measurementDetails.map((detail, index) => (
                <Detail key={index}>
                  <Label>
                    {t(
                      `screen.measurements.measurementDetails.${detail.label}`
                    )}
                  </Label>
                  <Value>{detail.value}</Value>
                </Detail>
              ))}
            </GridItemRight>
          </Grid>
        </DialogContent>
        <DialogActionsContainer>
          <DropdownMenu
            button={
              <DropdownButton variant="outlined">
                {t("screen.measurements.measurementDetails.actions")}
              </DropdownButton>
            }
          >
            <AccessGuard id={PurpleDeleteMeasurementKey}>
              <MenuItem onClick={() => setIsDeleteModalOpen(true)}>
                {t("screen.measurements.measurementDetails.deleteMeasurement")}
              </MenuItem>
            </AccessGuard>
          </DropdownMenu>
          <Button variant="contained" onClick={onClose}>
            {t("screen.measurements.measurementDetails.close")}
          </Button>
        </DialogActionsContainer>
      </Dialog>
      <ConfirmationModal
        open={isDeleteModalOpen}
        onConfirm={handleDeleteConfirmation}
        onCancel={() => setIsDeleteModalOpen(false)}
        title={t("modalConfirmation.deleteConfirmation.deleteMeasurement")}
        message={
          <Trans
            t={t}
            i18nKey="modalConfirmation.deleteConfirmation.deleteMeasurementsMessage"
          />
        }
        confirmButton={t("modalConfirmation.deleteConfirmation.confirmButton")}
        cancelButton={t("modalConfirmation.deleteConfirmation.cancelButton")}
      />
    </>
  );
};

export default MeasurementDetails;
