import { TableDesktopProps } from "./Table.types";
import { TableDesktopStyles } from "./TableDesktop.styles";

const { Table, Thead, Tbody } = TableDesktopStyles;

const TableDesktop: (props: TableDesktopProps) => JSX.Element = ({
  className = "",
  desktopHeader,
  desktopRows,
}) => {
  return (
    <Table className={className}>
      <Thead>{desktopHeader}</Thead>
      <Tbody>{desktopRows}</Tbody>
    </Table>
  );
};

export default TableDesktop;
