import { atom } from "jotai";
import { SortDirection, SortBy } from "se-libcore/filters";
import { ChipData } from "./MeasurementFilters/Filters.types";

export const measurementFilterAtoms = {
  measurementName: atom(""),
  measurementFilters: atom<ChipData[]>([]),
};

export const emailSearchAtom = atom("");

export const measurementSortingAtoms = {
  // default sorting is by time
  measurementSortBy: atom<SortBy>({
    prefix: "BASE",
    path: "createdOn",
  }),
  measurementSortDirection: atom<SortDirection>(SortDirection.DSC),
};
