import styled from "styled-components";

const Main = styled.main`
  flex: 1 0 0;
  align-self: center;
  display: flex;
  flex-direction: column;
  padding: 1.5rem 1.25rem;
  max-width: 90rem;
  width: 100%;

  ${({ theme }) => theme.breakpoints.up("laptop")} {
    padding: 2.5rem 2rem;
  }
`;

export const AppViewStyles = {
  Main,
};
