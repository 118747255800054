import { useState } from "react";

export const useSelection = <T extends { key: string }>() => {
  const [selectedItems, setSelectedItems] = useState<T[]>([]);
  const [isConfirmModalOpen, setConfirmModalOpen] = useState(false);
  const [tableKey, setTableKey] = useState(0);

  const handleCheckboxChange = (item: T, isChecked: boolean) => {
    if (isChecked) {
      setSelectedItems([...selectedItems, item]);
    } else {
      setSelectedItems(selectedItems.filter((i) => i.key !== item.key));
    }
  };

  const handleAction = (action: string) => {
    if (action === "delete") {
      setConfirmModalOpen(true);
    } else {
      // handle other actions
    }
  };

  const handleClearSelection = () => {
    setSelectedItems([]);
    setTableKey((prevKey) => prevKey + 1);
  };

  const handleDeleteConfirm = () => {
    // Clear the selection
    setSelectedItems([]);
    setConfirmModalOpen(false);
    handleClearSelection();
  };

  const handleModalCancel = () => {
    // Close the modal without deleting
    setConfirmModalOpen(false);
  };

  return {
    selectedItems,
    handleCheckboxChange,
    handleAction,
    handleDeleteConfirm,
    handleModalCancel,
    isConfirmModalOpen,
    handleClearSelection,
    tableKey,
  };
};
