import { createContext } from "react";
import { AuthResult } from "se-libcore/auth";

import { useAuth } from "../hooks/useAuth";

export const AuthResultContext = createContext<AuthResult | null>(null);

interface AuthProviderProps {
  children: React.ReactNode;
}

const AuthProvider: (props: AuthProviderProps) => JSX.Element = ({
  children,
}) => {
  const auth = useAuth();

  return (
    <AuthResultContext.Provider value={auth}>
      {children}
    </AuthResultContext.Provider>
  );
};

export default AuthProvider;
