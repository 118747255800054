import { TableMobileProps } from "./Table.types";
import { TableMobileStyles } from "./TableMobile.styles";

const { Body } = TableMobileStyles;

const TableMobile: (props: TableMobileProps) => JSX.Element = ({
  className = "",
  mobileRows,
}) => {
  return <Body className={className}>{mobileRows}</Body>;
};

export default TableMobile;
