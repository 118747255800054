import ky, { Options } from "ky";
import { HttpClient, HttpStatusCode } from "se-libcore/http";

import { api } from "se-libcore/api";
import { AccessControlConstants, clientId } from "se-libcore/auth";
import { LogService } from "se-libcore/services";

const prefixUrl = process.env.REACT_APP_API_URL || "http://localhost:3000";
const prefixUrlB2C = process.env.REACT_APP_B2C_DEV_API_URL || prefixUrl;

// eslint-disable-next-line no-console
console.log("used prefix url: ", prefixUrl);
// We use a different API URL for B2C on DEV environment for authentication.
const kyInstance = ky.create({ prefixUrl });
const kyInstanceB2C = ky.create({ prefixUrl: prefixUrlB2C });

const environment = process.env.NODE_ENV || "dev";
const REQUEST_TIMEOUT_DEVELOPMENT = 10000;
const REQUEST_TIMEOUT_PRODUCTION = 20000;

export const createClient = (options: Options = {}): HttpClient => {
  return kyInstance.extend({
    timeout:
      environment !== "production"
        ? REQUEST_TIMEOUT_DEVELOPMENT
        : REQUEST_TIMEOUT_PRODUCTION,
    ...options,
  });
};

export const client = createClient();

export const azureB2CClient = kyInstanceB2C;

const authClientExtendOptions: Options = {
  hooks: {
    beforeRequest: [
      (request) => {
        const accessToken = localStorage.getItem(
          AccessControlConstants.accessToken
        );

        if (accessToken) {
          request.headers.set("Authorization", `Bearer ${accessToken}`);
        } else {
          request.headers.delete("Authorization");
        }
      },
    ],
    afterResponse: [
      async (request, _options, response) => {
        if (response.status === HttpStatusCode.UNAUTHORIZED) {
          const refreshToken = localStorage.getItem(
            AccessControlConstants.refreshToken
          );

          if (refreshToken) {
            try {
              const { accessToken, userRoles } =
                await api.auth.acquireAccessToken(kyInstance, {
                  refreshToken,
                  clientId,
                });

              localStorage.setItem(
                AccessControlConstants.accessToken,
                accessToken
              );
              localStorage.setItem(
                AccessControlConstants.userRoles,
                JSON.stringify(userRoles)
              );

              request.headers.set("Authorization", `Bearer ${accessToken}`);

              return client(request);
            } catch (error) {
              LogService.error(
                "[access-control] Fail to exchange for new token",
                error
              );

              localStorage.removeItem(AccessControlConstants.accessToken);
              localStorage.removeItem(AccessControlConstants.refreshToken);
              localStorage.removeItem(AccessControlConstants.userRoles);
            }
          }
        }

        return response;
      },
    ],
  },
};

export const authClient = kyInstance.extend(authClientExtendOptions);
export const authB2CClient = kyInstanceB2C.extend(authClientExtendOptions);
