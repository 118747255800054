import { useTranslation } from "react-i18next";
import { UserDataRow } from "./UserTable.types";
import { TableMobileStyles } from "../../../shared/components/Table/TableMobile.styles";
import { DateTime, Email } from "../../../shared/components/Formats";

const { Arrow, Cell, Key, RowButton, Value, NestedRow } = TableMobileStyles;

const UserMobileRow: (props: { data: UserDataRow }) => JSX.Element = ({
  data,
}) => {
  const { t } = useTranslation(undefined, {
    keyPrefix: "screen.users.table",
  });

  return (
    <RowButton>
      <Cell expand>
        <Key>
          <Email value={data.preferredUsername} />
        </Key>
        <div>{data.partialId}</div>
      </Cell>
      <Value>
        <NestedRow>
          <Key>{t("role")}</Key>
          <Value>{data.role}</Value>
        </NestedRow>
        <NestedRow>
          <Key>{t("state")}</Key>
          <Value>{data.state ? t("active") : t("inactive")}</Value>
        </NestedRow>
        <NestedRow>
          <Key>{t("createdOn")}</Key>
          <Value>
            <DateTime value={data.createdOn} />
          </Value>
        </NestedRow>
        <NestedRow>
          <Key>{t("modifiedOn")}</Key>
          <Value>
            <DateTime value={data.modifiedOn} />
          </Value>
        </NestedRow>
      </Value>
      <Arrow />
    </RowButton>
  );
};

export default UserMobileRow;
