import { useAtom } from "jotai";
import { useTranslation } from "react-i18next";
import throttle from "lodash/throttle";
import { useMemo } from "react";
import { PageStyles } from "../../../layout/Page.styles";
import SearchField from "../../../shared/components/SearchField/SearchField";
import { measurementFilterAtoms } from "../MeasurementFilter.atom";

const { Toolbar } = PageStyles;

export const ConsumerMeasurementSearchField = () => {
  const { t } = useTranslation(undefined, { keyPrefix: "screen.measurements" });
  const [, setMeasurementName] = useAtom(
    measurementFilterAtoms.measurementName
  );

  const throttledSetMeasurementName = useMemo(() => {
    return throttle(
      (value: string) => {
        setMeasurementName(value);
      },
      500,
      { leading: false, trailing: true }
    );
  }, [setMeasurementName]);

  return (
    <Toolbar>
      <SearchField
        placeholder={t("searchByName")}
        onChange={(e) => {
          e.preventDefault();
          throttledSetMeasurementName(e.target.value);
        }}
      />
    </Toolbar>
  );
};
