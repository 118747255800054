import styled from "styled-components";
import { Button, ButtonProps, Stack } from "@mui/material";
import { ArrowDropDown as MuiArrowDropDown } from "@mui/icons-material";

const ArrowDropDown = styled(MuiArrowDropDown)`
  && {
    margin-right: -4px;
  }
`;

const DropdownButton: (props: ButtonProps) => JSX.Element = ({
  children,
  ...rest
}) => {
  return (
    <Button {...rest}>
      <Stack direction="row" spacing={0.5} alignItems="center">
        <span>{children}</span>
        <ArrowDropDown aria-hidden="true" />
      </Stack>
    </Button>
  );
};

export default DropdownButton;
