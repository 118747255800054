import styled from "styled-components";
import IconButton from "@mui/material/IconButton";
import FilterListIcon from "@mui/icons-material/FilterList";
import { Chip, Button } from "@mui/material";

export const FilterBarContainer = styled.div`
  align-items: center;
  background-color: ${({ theme }) => theme.palette.brandGray.light};
  border-radius: 0.3rem;
  color: ${({ theme }) => theme.palette.primary.main};
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
  min-height: 3rem;
  padding: 0 0.625rem;
  position: relative;
  width: 100%;
`;
export const FilterBarInnerContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const FilterBarLabel = styled(IconButton)`
  align-item: center;
  border: none;
  color: ${({ theme }) => theme.palette.primary.main};
  display: flex;
  font-size: 1rem;
  font-weight: bold;
  height: 100%;
  margin-right: 1rem;
  padding: 0.125rem;
`;
export const StyledFilterbarIcon = styled(FilterListIcon)`
  font-size: 1.75rem;
  margin-right: 0.5rem;
  margin-top: -0.125rem;
`;

export const StyledFilterChip = styled(Chip)`
  border-radius: 0.3rem;
  margin: 0.3rem 0.625rem 0.3rem 0;
  padding: 0.625rem 0rem;
  & div {
    display: inline-block;
    font-weight: bold;
    margin-right: 0.5rem;
  }
`;
export const StyledClearButton = styled(Button)`
  font-size: 1rem;
  font-weight: normal;
  min-width: 7.5rem;
  svg {
    font-size: 1rem;
  }
`;
