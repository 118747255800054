import styled from "styled-components";
import { Button } from "@mui/material";
import { visuallyHidden } from "@mui/utils";

const ToggleButton = styled(Button).attrs({
  variant: "text",
  color: "inherit",
})`
  border-radius: 0;
  min-width: 0;
  margin: -1rem -1rem -1rem 0;
  padding: 1.5rem 1.25em 1.5rem;
`;

const ToggleLabel = styled.span.attrs({ style: visuallyHidden })``;

const OuterContainer = styled.div`
  background: ${({ theme }) => theme.palette.primary.dark};
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
`;

const InnerContainer = styled.div`
  flex: 1 0 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 1.25rem 1.25rem;
  gap: 0.5rem;

  &::before {
    content: "";
    display: block;
    width: 100%;
    height: 1rem;
    border-top: 1px solid ${({ theme }) => theme.palette.background.default}28;
  }

  .MuiButton-root {
    width: 100%;

    &.NavigationButton {
      font-size: ${({ theme }) => theme.typography.h5.fontSize};
    }

    &.active {
      background: ${({ theme }) => theme.palette.primary.main};
    }
  }
`;

const LoggedInAs = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin: auto 0 0.75rem;
  padding: 1.25rem 1.25rem 1.75rem;
  border-bottom: 1px solid ${({ theme }) => theme.palette.background.default}50;
  opacity: 0.55;
  text-align: center;

  svg {
    font-size: 200%;
    margin-bottom: 1rem;
  }
`;

export const NavigationMenuMobileStyles = {
  InnerContainer,
  LoggedInAs,
  OuterContainer,
  ToggleButton,
  ToggleLabel,
};
