import MeasurementsScreen from "../screens/measurements/MeasurementsScreen";
import DevicesScreen from "../screens/devices/DevicesScreen";
import SubscriptionsScreen from "../screens/subscriptions/SubscriptionsScreen";
import UsersScreen from "../screens/users/UsersScreen";

type ApplicationRoute = {
  path: string;
  screen: JSX.Element;
};

const appRoutes: ApplicationRoute[] = [
  {
    path: "/measurements",
    screen: <MeasurementsScreen />,
  },
  {
    path: "/devices",
    screen: <DevicesScreen />,
  },
  {
    path: "/subscriptions",
    screen: <SubscriptionsScreen />,
  },
  {
    path: "/users",
    screen: <UsersScreen />,
  },
];

export default appRoutes;
