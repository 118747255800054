import { Button, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { GetLoginUrlResult } from "se-libcore/auth";
import { LogService } from "se-libcore/services";
import { LoadingSpinner } from "se-libcore/components";
import { useGetLoginUrl } from "../../shared/lib/auth/hooks/useGetLoginUrl";

const Login = () => {
  const { t } = useTranslation(undefined, {
    keyPrefix: "authentication",
  });

  const loginRedirect = (data: GetLoginUrlResult) => {
    if (!data?.url) {
      LogService.error("[access-control] Invalid login url response");

      return;
    }

    try {
      window.location.href = data.url;
    } catch (err) {
      LogService.error("[access-control] Error while trying to open login url");
      LogService.error({ error: err });
    }
  };

  const { data, isLoading, error } = useGetLoginUrl();
  return (
    <>
      <Button
        variant="contained"
        disabled={isLoading || !data?.url}
        onClick={() => {
          if (data) loginRedirect(data);
        }}
      >
        {isLoading ? (
          <LoadingSpinner fullscreen={false} />
        ) : (
          t("login.buttonLabel")
        )}
      </Button>
      {!!error && (
        <>
          <Typography color="error">{t("login.cannotGetLoginUrl")}</Typography>
          <Typography color="error">
            {error.getFormattedErrorMessage(t)}
          </Typography>
        </>
      )}
    </>
  );
};

export default Login;
