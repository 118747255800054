import React, { createRef, useLayoutEffect, useState } from "react";
import { useMediaQuery, useTheme } from "@mui/material";
import { AppHeaderStyles } from "./AppHeader.styles";
import NavigationMenuDesktop from "../navigation/NavigationMenuDesktop";
import NavigationMenuMobile from "../navigation/NavigationMenuMobile";
import { useAuthResult } from "../shared/lib/auth/hooks/useAuthResult";

const { Container, Logo, Name, Title } = AppHeaderStyles;

const AppHeader = () => {
  const theme = useTheme();
  const [containerHeight, setContainerHeight] = useState<number>(0);
  const containerRef = createRef<HTMLElement>();
  const showMobileNav = useMediaQuery(theme.breakpoints.down("laptop"));
  const { user } = useAuthResult();
  const userName = user?.preferredUsername ?? user?.username ?? "";

  useLayoutEffect(() => {
    setContainerHeight(containerRef.current?.clientHeight || 0);
  }, [containerRef]);

  return (
    <Container ref={containerRef} role="banner">
      <Title>
        <Logo />
        <Name>Purpl PRO Portal</Name>
      </Title>
      {showMobileNav ? (
        <NavigationMenuMobile userName={userName} topOffset={containerHeight} />
      ) : (
        <NavigationMenuDesktop userName={userName} />
      )}
    </Container>
  );
};

export default AppHeader;
