import styled from "styled-components";
import {
  Button as MuiButton,
  Table as MuiTable,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TableRow,
} from "@mui/material";

// Define base styles and extensions to MUI table components,
// create aliases to match the underlying HTML element names

const Table = styled(MuiTable)``;
const Thead = styled(TableHead)``;
const Tbody = styled(TableBody)``;
const Tfoot = styled(TableFooter)``;
const Tr = styled(TableRow)``;

const Td = styled(TableCell).withConfig({
  shouldForwardProp: (prop) =>
    !["highlight", "numeric", "shrink"].includes(prop),
})<{
  highlight?: boolean;
  numeric?: boolean;
  shrink?: boolean;
}>`
  background: ${({ highlight, theme }) =>
    highlight ? theme.palette.brandPurple.light : "inherit"};
  color: ${({ highlight, theme }) =>
    highlight ? theme.palette.primary.main : "inherit"};
  text-align: ${({ numeric }) => (numeric ? "right" : "inherit")};
  width: ${({ shrink }) => (shrink ? 0 : "auto")};
`;

const Th = styled(Td).attrs({ variant: "head" })``;

const Button = styled(MuiButton).attrs({ variant: "text", size: "small" })`
  border-radius: 0;
  min-width: 0;
  padding: 0.75rem 1rem;
`;

const Buttons = styled.div`
  display: flex;
`;

const SortableTh = styled(Th)`
  cursor: pointer;
  align-item: center;
  gap: 5px;
  white-space: nowrap;
`;

const SortIconContainer = styled.div`
  font-size: 1rem;

  .defaultSortIcon {
    color: ${({ theme }) => theme.palette.primary.main};
    opacity: 35%;
  }
`;

const SortableDiv = styled.div`
  display: flex;
  cursor: pointer;
  align-item: center;
  gap: 5px;
`;

export const TableDesktopStyles = {
  Button,
  Buttons,
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Td,
  Th,
  SortableTh,
  SortIconContainer,
  SortableDiv,
};
