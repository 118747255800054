import { ReactNode } from "react";
import { Trans, useTranslation } from "react-i18next";
import { FieldErrors } from "react-hook-form";

interface useFilterConfigHookProps {
  watch: (value: string, defaultValue?: string) => string;
  setValue: (name: string, defaultValue?: string) => void;
}

const useFilterConfigHook = ({ watch, setValue }: useFilterConfigHookProps) => {
  const { t } = useTranslation(undefined, { keyPrefix: "filterModal" });

  const filterRules = {
    deviceSerialNumber: {
      id: "deviceSerialNumber",
      rules: {
        pattern: /^([A-Za-z0-9]?\w+)$/,
      },
      inputType: "text",
      inputProps: {},
      default: "",
    },
    measuredAtFrom: {
      id: "measuredAtFrom",
      rules: {},
      inputType: "datetime",
      inputProps: {
        min: "2018-01-01T00:00",
        max: "2030-01-01T00:00",
      },
      default: "",
    },
    measuredAtTo: {
      id: "measuredAtTo",
      rules: {},
      inputType: "datetime",
      inputProps: {
        min: "2018-01-01T00:00",
        max: "2030-01-01T00:00",
      },
      default: "",
    },
    productName: {
      id: "productName",
      rules: {},
      inputType: "select",
      inputProps: {},
      default: "",
    },
    // TODO: Enable this line when the filters are supported by the backend
    // thcFrom: {
    //   id: "thcFrom",
    //   rules: { min: 0, max: 100 },
    //   inputType: "number",
    //   inputProps: {
    //     step: 1,
    //     min: "0",
    //     max: "100",
    //     placeholder: t("min") as string,
    //   },
    //   default: "",
    // },
    // thcTo: {
    //   id: "thcTo",
    //   rules: { min: 0, max: 100 },
    //   inputType: "number",
    //   inputProps: {
    //     step: 1,
    //     min: "0",
    //     max: "100",
    //     placeholder: t("max") as string,
    //   },
    //   default: "",
    // },
    // cbdFrom: {
    //   id: "cbdFrom",
    //   rules: { min: 0, max: 100 },
    //   inputType: "number",
    //   inputProps: {
    //     step: 1,
    //     min: "0",
    //     max: "100",
    //     placeholder: t("min") as string,
    //   },
    //   default: "",
    // },
    // cbdTo: {
    //   id: "cbdTo",
    //   rules: { min: 0, max: 100 },
    //   inputType: "number",
    //   inputProps: {
    //     step: 1,
    //     min: "0",
    //     max: "100",
    //     placeholder: t("max") as string,
    //   },
    //   default: "",
    // },
    // moistureFrom: {
    //   id: "moistureFrom",
    //   rules: { min: 0, max: 100 },
    //   inputType: "number",
    //   inputProps: {
    //     step: 1,
    //     min: "0",
    //     max: "100",
    //     placeholder: t("min") as string,
    //   },
    //   default: "",
    // },
    // moistureTo: {
    //   id: "moistureTo",
    //   rules: { min: 0, max: 100 },
    //   inputType: "number",
    //   inputProps: {
    //     step: 1,
    //     min: "0",
    //     max: "100",
    //     placeholder: t("max") as string,
    //   },
    //   default: "",
    // },
    // waterActivityFrom: {
    //   id: "waterActivityFrom",
    //   rules: { min: 0, max: 1 },
    //   inputType: "number",
    //   inputProps: {
    //     step: 0.1,
    //     min: "0",
    //     max: "1",
    //     placeholder: t("min") as string,
    //   },
    //   default: "",
    // },
    // waterActivityTo: {
    //   id: "waterActivityTo",
    //   rules: { min: 0, max: 1 },
    //   inputType: "number",
    //   inputProps: {
    //     step: 0.1,
    //     min: "0",
    //     max: "1",
    //     placeholder: t("max") as string,
    //   },
    //   default: "",
    // },
  } as const;

  const filterProducts = [
    { name: t("filterOptions.productNameLabels.all"), value: "" },
    {
      name: t("filterOptions.productNameLabels.flower"),
      value: t("filterOptions.productNameLabels.flower"),
    },
    {
      name: t("filterOptions.productNameLabels.distillate"),
      value: t("filterOptions.productNameLabels.distillate"),
    },
  ];

  /*
    Iterates over the filter rules and reset all values
  */
  const resetForm = () => {
    Object.entries(filterRules).map((filterRule) => {
      setValue(filterRule[0], "");
      return true;
    });
  };

  /*
    create this fields dynamically, instead of initialize each values independent
    instead of 
    deviceSerialNumber = watch();
    moistureFrom = watch();
  */
  const fieldWatcher = (() => {
    const fields = {};
    Object.entries(filterRules).map((filterRule) => {
      // eslint-disable-next-line
      // @ts-ignore
      fields[filterRule[0]] = watch(filterRule[0], filterRule[1].default);
      return true;
    });
    return fields;
  })();

  const renderHelperText = (
    errors: FieldErrors,
    fieldId: string
  ): ReactNode => {
    let messageKey = "";
    const errorType = errors[fieldId]?.type;
    // TODO: Replace const with let the filters are supported by the backend
    const max = 100;

    // create different error messages depends on the fields
    switch (errorType) {
      case "min":
      case "max":
        messageKey = "filterErrorMessages.valueBetween";
        break;
      case "validate":
        // TODO: Enable this line when the filters are supported by the backend
        // // eslint-disable-next-line no-case-declarations
        // const validateFilterRules = [
        //   filterRules.thcFrom.id,
        //   filterRules.cbdFrom.id,
        //   filterRules.moistureFrom.id,
        //   filterRules.waterActivityFrom.id,
        // ] as const;
        // if (
        //   validateFilterRules.includes(
        //     fieldId as (typeof validateFilterRules)[number]
        //   )
        // ) {
        //   messageKey = "filterErrorMessages.minValueSmallerthanMax";
        // } else if (fieldId === filterRules.waterActivityFrom.id) {
        //   messageKey = "filterErrorMessages.minValueSmallerthanMax";
        //   max = 1;
        // } else if (fieldId === filterRules.measuredAtFrom.id) {
        //   messageKey = "filterErrorMessages.startTimeBeforeEndTime";
        // }
        break;
      case "pattern":
        messageKey = "filterErrorMessages.invalidDeviceID";
        break;
      default:
        break;
    }

    if (messageKey !== "") {
      return (
        <Trans
          t={t}
          i18nKey={messageKey}
          values={{
            field: errorType,
            min: 1,
            max,
          }}
        />
      );
    }

    return null;
  };

  return {
    filterRules,
    filterProducts,
    fieldWatcher,
    resetForm,
    renderHelperText,
  };
};

export default useFilterConfigHook;
