import { useCallback, useMemo } from "react";
import { postLogoutRedirectUri } from "se-libcore/auth";
import { useGetLoginUrl } from "./useGetLoginUrl";
import { useAuthResult } from "./useAuthResult";

export const useLogout = () => {
  const { data, isLoading } = useGetLoginUrl();
  const { logout } = useAuthResult();

  const handleLogout = useCallback(() => {
    if (data?.url) {
      // TODO: Backend should return the logout url
      // instead of building this ourselves
      const loginUrl = new URL(data.url);
      loginUrl.search = "";
      loginUrl.hash = "";
      const logoutUrl = `${loginUrl
        .toString()
        .replace(
          "/authorize",
          "/logout"
        )}?post_logout_redirect_uri=${postLogoutRedirectUri}&logout=success`;
      logout();
      window.location.href = logoutUrl;
    }
  }, [data?.url, logout]);

  return useMemo(
    () => ({
      handleLogout,
      isLoading,
    }),
    [handleLogout, isLoading]
  );
};
