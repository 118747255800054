import styled from "styled-components";
import { Button, Stack } from "@mui/material";

export const LoginBlock = styled(Stack).attrs({
  alignItems: "center",
})``;

export const LoginButton = styled(Button)`
  width: 60%;
  margin-top: ${({ theme }) => theme.spacing(2)};
  margin-bottom: ${({ theme }) => theme.spacing(4)};
  font-size: ${({ theme }) => theme.typography.h5.fontSize};
`;
