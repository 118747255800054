import { Permission } from "./permission.types";

export const verify = (
  permissionKey: Permission,
  permissions: Permission[] | undefined
): boolean => {
  if (permissions === undefined) return false;

  return permissions.some(
    ({ resource: { service, type, objectId }, operation }) => {
      const {
        resource: { service: keyService, type: keyType, objectId: keyObjectId },
        operation: keyOperation,
      } = permissionKey;

      return (
        (service === "*" || service === keyService) &&
        (type === "*" || type === keyType) &&
        (objectId === "*" || objectId === keyObjectId) &&
        (operation === "*" || operation === keyOperation)
      );
    }
  );
};
