import { useState, useEffect } from "react";
import { Button, MenuItem, Dialog } from "@mui/material";
import Grid from "@mui/material/Grid";
import { Trans, useTranslation } from "react-i18next";
import { useAtom } from "jotai";
import DropdownMenu from "../../../shared/components/DropdownMenu";
import DropdownButton from "../../../shared/components/DropdownButton";
import { UserDataRow } from "./UserTable.types";
import { DateTime } from "../../../shared/components/Formats";
import { UserDetailsStyles } from "./UserDetails.styles";
import ConfirmationModal from "../../../shared/components/ConfirmationModal/ConfirmationModal";
import UserProductsTable from "./UserProductsTable";
import { UserAtoms } from "./User.atom";

const {
  Detail,
  Label,
  Value,
  BoldValue,
  GridItem,
  StyledDialogTitle,
  StyledDialogContent,
  StyledDialogActions,
  Title,
} = UserDetailsStyles;

interface UserDetailProps {
  isOpen: boolean;
  onClose: () => void;
  data: UserDataRow;
}

const UserDetails: (props: UserDetailProps) => JSX.Element = ({
  isOpen,
  onClose,
  data,
}) => {
  const { t } = useTranslation();
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [, setSelectedUserData] = useAtom(UserAtoms.selectedUser);

  useEffect(() => {
    setSelectedUserData(data);
  }, [data, setSelectedUserData]);

  const userDetails = [
    {
      label: "userId",
      value: (
        // Extract the last 6 digits out to make it bold
        <>
          {data.key.slice(0, -6)}
          <BoldValue>{data.partialId}</BoldValue>
        </>
      ),
    },
    { label: "role", value: data.role },
    {
      label: "state",
      value: data.state
        ? t("screen.users.table.active")
        : t("screen.users.table.inactive"),
      options: [
        t("screen.users.table.active"),
        t("screen.users.table.inactive"),
      ],
    },
    { label: "createdOn", value: <DateTime value={data.createdOn} /> },
    { label: "modifiedOn", value: <DateTime value={data.modifiedOn} /> },
  ];

  const handleDeleteConfirm = () => {
    // TODO: Implement delete functionality
    setIsDeleteModalOpen(false);
  };

  return (
    <>
      <Dialog fullWidth maxWidth="laptop" open={isOpen} onClose={onClose}>
        <StyledDialogTitle variant="h3">
          <Trans
            t={t}
            i18nKey="screen.users.userDetails.title"
            values={{ userName: data.preferredUsername }}
          />
        </StyledDialogTitle>
        <StyledDialogContent>
          <Grid container spacing={2}>
            <GridItem item mobileSmall={12} mobileLarge={6}>
              <Title variant="h3">
                {t("screen.users.userDetails.coreDetails")}
              </Title>
              {userDetails.map((detail, index) => (
                <Detail key={index}>
                  <Label>{t(`screen.users.userDetails.${detail.label}`)}</Label>
                  {detail.options ? (
                    <DropdownMenu
                      button={
                        <DropdownButton variant="outlined">
                          {detail.value}
                        </DropdownButton>
                      }
                    >
                      {detail.options.map((option, index) => (
                        <MenuItem key={index}>{t(option)}</MenuItem>
                      ))}
                    </DropdownMenu>
                  ) : (
                    <Value>{detail.value}</Value>
                  )}
                </Detail>
              ))}
            </GridItem>
            <Grid item mobileSmall={12} mobileLarge={6}>
              <Title variant="h3">
                {t("screen.users.userDetails.products")}
              </Title>
              <UserProductsTable />
            </Grid>
          </Grid>
        </StyledDialogContent>
        <StyledDialogActions style={{ justifyContent: "space-between" }}>
          <DropdownMenu
            button={
              <DropdownButton variant="outlined">
                {t("screen.users.userDetails.actions")}
              </DropdownButton>
            }
          >
            <MenuItem onClick={() => setIsDeleteModalOpen(true)}>
              {t("screen.users.userDetails.deletePermanently")}
            </MenuItem>
            <MenuItem>
              {t("screen.users.userDetails.forcePasswordReset")}
            </MenuItem>
          </DropdownMenu>
          <Button variant="contained" onClick={onClose}>
            {t("screen.users.userDetails.close")}
          </Button>
        </StyledDialogActions>
      </Dialog>
      <ConfirmationModal
        open={isDeleteModalOpen}
        onConfirm={handleDeleteConfirm}
        onCancel={() => setIsDeleteModalOpen(false)}
        title={t("modalConfirmation.deleteConfirmation.deleteUsersTitle")}
        message={
          <Trans
            t={t}
            i18nKey="modalConfirmation.deleteConfirmation.deleteUsersMessage"
          />
        }
        confirmButton={t("modalConfirmation.deleteConfirmation.confirmButton")}
        cancelButton={t("modalConfirmation.deleteConfirmation.cancelButton")}
      />
    </>
  );
};

export default UserDetails;
