import { Trans, useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { MenuItem } from "@mui/material";
import { PageStyles } from "../../layout/Page.styles";
import MeasurementTable from "./MeasurementTable/MeasurementTable";
import { MeasurementDataRow } from "./MeasurementTable/MeasurementTable.types";
import ConfirmationModal from "../../shared/components/ConfirmationModal/ConfirmationModal";
import { useSelection } from "../../hooks/useSelection";
import SelectedItemsIndicator from "../../shared/components/SelectedItemsIndicator";
import { ConsumerMeasurementSearchField } from "./MeasurementFilters/ConsumerMeasurementSearchField";
import { AdminMeasurementSearchField } from "./MeasurementFilters/AdminMeasurementSearchField";
import { useIsUserAdmin } from "../../shared/useIsUserAdmin";
import { useDeleteMeasurement } from "../../hooks/useDeleteMeasurement";
import { FilterBar } from "./MeasurementFilters/FilterBar";
import {
  Container,
  StyledDropdownMenu,
  StyledDivider,
  GreenOutlinedButton,
} from "./MeasurementTable/MeasurementScreen.styles";

const { Body, Header, Title } = PageStyles;

const MeasurementsScreen = () => {
  const { mutate: deleteMeasurement } = useDeleteMeasurement();
  const { t } = useTranslation();

  const {
    selectedItems,
    handleCheckboxChange,
    handleAction,
    // handleDeleteConfirm,
    handleModalCancel,
    isConfirmModalOpen,
    handleClearSelection,
    tableKey,
  } = useSelection<MeasurementDataRow>();

  const isUserAdministrator = useIsUserAdmin();

  const navigate = useNavigate();

  const handleDeleteConfirmation = async () => {
    await Promise.all(
      selectedItems.map(async (item) => {
        const measurementId = item.key.replace("measurement-", "");
        await deleteMeasurement({ measurementId, username: item.email });
      })
    );
    navigate("/measurements");
  };

  return (
    <>
      <Header>
        <Title>{t("screen.measurements.title")}</Title>
        {isUserAdministrator ? (
          <AdminMeasurementSearchField />
        ) : (
          <ConsumerMeasurementSearchField />
        )}
      </Header>
      <Body>
        <Container>
          <FilterBar />
        </Container>
        <Container>
          {/* Only render the DropdownMenu when there are selected measurement */}
          {selectedItems.length > 0 && (
            <>
              <SelectedItemsIndicator
                count={selectedItems.length}
                selectedTextKey="measurementsSelected"
                translationPrefix="screen.measurements"
              />
              <StyledDropdownMenu
                button={
                  <GreenOutlinedButton variant="outlined">
                    {t("screen.measurements.action")}
                  </GreenOutlinedButton>
                }
              >
                <MenuItem onClick={() => handleAction("delete")}>
                  {t("screen.measurements.deleteMeasurement")}
                </MenuItem>
                <StyledDivider />
                <MenuItem onClick={handleClearSelection}>
                  {t("screen.measurements.clearSelection")}
                </MenuItem>
              </StyledDropdownMenu>
            </>
          )}
        </Container>
        <MeasurementTable
          key={tableKey}
          onCheckboxChange={handleCheckboxChange}
          selectedItems={selectedItems}
        />
      </Body>
      <ConfirmationModal
        open={isConfirmModalOpen}
        onConfirm={handleDeleteConfirmation}
        onCancel={handleModalCancel}
        title={t("modalConfirmation.deleteConfirmation.deleteMeasurement")}
        message={
          <Trans
            t={t}
            i18nKey="modalConfirmation.deleteConfirmation.deleteMeasurementsMessage"
          />
        }
        confirmButton={t("modalConfirmation.deleteConfirmation.confirmButton")}
        cancelButton={t("modalConfirmation.deleteConfirmation.cancelButton")}
      />
    </>
  );
};

export default MeasurementsScreen;
