import { ThemeOptions } from "@mui/material";

export const typography: ThemeOptions["typography"] = {
  fontFamily: "Rajdhani, Arial, sans-serif",
  fontSize: 14, // 16px
  fontWeightRegular: 500,
  h1: {
    fontSize: "2.75rem", // 44px
    fontWeight: 700,
  },
  h2: {
    fontSize: "2.25rem", // 36px
    fontWeight: 700,
  },
  h3: {
    fontSize: "1.75rem", // 28px
    fontWeight: 700,
  },
  h4: {
    fontSize: "1.5rem", // 24px
    fontWeight: 700,
  },
  h5: {
    fontSize: "1.25rem", // 20px
    fontWeight: 700,
  },
  h6: {
    fontSize: "1rem",
    fontWeight: 700,
  },
  button: {
    fontWeight: 700,
  },
};
