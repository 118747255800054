import { useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { Button } from "@mui/material";
import { AccountCircle } from "@mui/icons-material";
import NavigationButtons from "./NavigationButtons";
import { ReactComponent as MobileMenuIcon } from "../images/mobile_menu_icon.svg";
import { NavigationMenuProps } from "./NavigationMenu.types";
import { NavigationMenuMobileStyles } from "./NavigationMenuMobile.styles";
import { useLogout } from "../shared/lib/auth/hooks/useLogout";
import { useIsAuthenticated } from "../shared/useIsAuthenticated";

const {
  InnerContainer,
  LoggedInAs,
  OuterContainer,
  ToggleButton,
  ToggleLabel,
} = NavigationMenuMobileStyles;

const NavigationMenuMobile: (props: NavigationMenuProps) => JSX.Element = ({
  topOffset = 0,
  userName,
}) => {
  const { t } = useTranslation();
  const { handleLogout, isLoading } = useLogout();
  const [menuOpen, setMenuOpen] = useState<boolean>(false);
  const isAuthenticated = useIsAuthenticated();

  const toggleMenu = () => {
    setMenuOpen((currentlyOpen: boolean) => {
      const action = currentlyOpen ? "remove" : "add";
      document.body.classList[action]("mobile-nav-open");
      return !currentlyOpen;
    });
  };

  const handleContainerClick = (event: React.MouseEvent<HTMLDivElement>) => {
    const target = event.target as Element;
    if (
      target.classList.contains("MuiButton-root") &&
      !target.classList.contains("Mui-disabled")
    ) {
      toggleMenu();
    }
  };

  return (
    <nav role="navigation" aria-label={t("navControls.mainMenuLabel") || ""}>
      <ToggleButton
        aria-controls="main-menu"
        aria-expanded={menuOpen ? "true" : "false"}
        aria-haspopup="true"
        onClick={toggleMenu}
      >
        <MobileMenuIcon role="presentation" />
        <ToggleLabel>
          {menuOpen
            ? t("navControls.closeMainMenu")
            : t("navControls.openMainMenu")}
        </ToggleLabel>
      </ToggleButton>

      {menuOpen && (
        <OuterContainer
          aria-hidden="false"
          id="main-menu"
          onClick={handleContainerClick}
          style={{ top: topOffset }}
        >
          <InnerContainer>
            <NavigationButtons />
            {isAuthenticated && (
              <>
                <LoggedInAs>
                  <AccountCircle role="presentation" />
                  <div>
                    <Trans
                      t={t}
                      i18nKey="userMenu.loggedInAs"
                      values={{ userName }}
                    />
                  </div>
                </LoggedInAs>

                <Button
                  variant="text"
                  color="inherit"
                  disabled={isLoading}
                  onClick={handleLogout}
                >
                  {t("userMenu.logOut")}
                </Button>
              </>
            )}
          </InnerContainer>
        </OuterContainer>
      )}
    </nav>
  );
};

export default NavigationMenuMobile;
