import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Typography } from "@mui/material";
import { useLocation } from "react-router-dom";
import LegalDocument from "../screens/home/LegalDocument";
import { LegalDocumentTypes } from "../screens/home/LegalDocument.types";
import {
  FooterContainer,
  FooterContent,
  StyledLink,
  VersionText,
} from "./AppFooter.styles";
import { versionInfo } from "../shared/versionInfo";

const year = new Date().getFullYear();

const AppFooter = () => {
  const { t } = useTranslation(undefined, { keyPrefix: "screen.welcomeHome" });
  const [legalDocumentType, setLegalDocumentType] =
    useState<null | LegalDocumentTypes>();
  const location = useLocation();

  useEffect(() => {
    if (location.hash === "#privacy") {
      setLegalDocumentType("privacyPolicy");
    }
    if (location.hash === "#tac") {
      setLegalDocumentType("termsAndConditions");
    }
  }, [location]);

  return (
    <>
      <FooterContainer>
        <FooterContent>
          <Typography variant="body2">
            &copy; {year} {t("purplScientific")}
          </Typography>
        </FooterContent>
        <FooterContent>
          <StyledLink>
            <Typography onClick={() => setLegalDocumentType("privacyPolicy")}>
              {t("privacyPolicy")}
            </Typography>
          </StyledLink>
          <StyledLink>
            <Typography
              onClick={() => setLegalDocumentType("termsAndConditions")}
            >
              {t("termsAndConditions")}
            </Typography>
          </StyledLink>
          <div>
            <VersionText>
              v{versionInfo.version}-{versionInfo.commitHash}
            </VersionText>
          </div>
        </FooterContent>
      </FooterContainer>
      {legalDocumentType !== null &&
        typeof legalDocumentType !== "undefined" && (
          <LegalDocument
            open={Boolean(legalDocumentType)}
            handleClose={() => setLegalDocumentType(null)}
            legalDocument={legalDocumentType}
          />
        )}
    </>
  );
};

export default AppFooter;
