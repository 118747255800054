import { useEffect, SyntheticEvent, ReactNode } from "react";
import { useTranslation } from "react-i18next";
import { useForm, SubmitHandler, Controller } from "react-hook-form";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  MenuItem,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { useAtom } from "jotai";
import { measurementFilterAtoms } from "../MeasurementFilter.atom";
import useFilterConfigHook from "./useFilterConfig";
import { SwapButton } from "./SwapButton";
import {
  validateEndDateValid,
  validateMinMaxValues,
} from "./customValidationRules";

import { ChipData, ChipDataValues, FilterTypes } from "./Filters.types";
import {
  StyledFlexContainer,
  StyledDivider,
  StyledAccordion,
  StyledAccordionSummary,
  StyledAccordionDetails,
  StyledSelect,
  StyledTextField,
} from "./FilterDialog.styles";

interface FilterDialogProps {
  isOpen: boolean;
  closeDialog: () => void;
  selectedFilter: string[];
  changeSelectedFilter: (index: string | null) => void;
  resetFormFields: boolean;
  setResetFormFields: (value: boolean) => void;
}

interface Inputs {
  [key: string]: string;
}

export const FilterDialog = ({
  isOpen,
  closeDialog,
  selectedFilter,
  changeSelectedFilter,
  resetFormFields,
  setResetFormFields,
}: FilterDialogProps) => {
  const [filters, setFilters] = useAtom(
    measurementFilterAtoms.measurementFilters
  );

  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    resetField,
    clearErrors,
    control,
    formState: { errors },
  } = useForm();

  const {
    filterRules,
    filterProducts,
    fieldWatcher,
    resetForm,
    renderHelperText,
  } = useFilterConfigHook({
    watch,
    setValue,
  });

  const cleanKey = (key: string) => {
    return key.replace(/From|To/gi, "");
  };

  const createNewFilterObject = (
    key: FilterTypes["type"],
    value: ChipDataValues
  ): ChipData => {
    const newFilterObject = {
      key,
      label: t(`filterModal.filterOptions.${key}`),
      type: key as FilterTypes["type"],
      values: value,
    };

    const cleanedKey = cleanKey(key);
    if (key.endsWith("From")) {
      newFilterObject.label = t(`filterModal.filterOptions.${cleanedKey}`);
      newFilterObject.type = cleanedKey as FilterTypes["type"];
      newFilterObject.values = { from: value.from };
    } else if (key.endsWith("To")) {
      newFilterObject.label = t(`filterModal.filterOptions.${cleanedKey}`);
      newFilterObject.type = cleanedKey as FilterTypes["type"];
      newFilterObject.values = { to: value.to };
    }

    return newFilterObject;
  };
  const createFilterValue = (
    key: FilterTypes["type"],
    value: ChipDataValues
  ) => {
    let newValue = {};
    if (key.endsWith("From")) {
      newValue = { ...newValue, from: value };
    } else if (key.endsWith("To")) {
      newValue = { ...newValue, to: value };
    } else {
      newValue = { ...newValue, value };
    }
    return newValue;
  };

  // submit event handler
  const handleOnSubmit: SubmitHandler<Inputs> = (formData) => {
    const dataObject = Object.entries(formData);
    const updatedFilters = [] as ChipData[];
    dataObject.map(([key, value] = formData as unknown as [string, string]) => {
      if (typeof value === "undefined" || value === "") {
        return null;
      }

      const findIndex = updatedFilters.findIndex(
        (filter) => filter.type === cleanKey(key)
      );

      const newValue = createFilterValue(
        key as FilterTypes["type"],
        value as ChipDataValues
      );

      const newFilterObject = createNewFilterObject(
        cleanKey(key) as FilterTypes["type"],
        newValue as ChipDataValues
      );

      if (findIndex === -1) {
        // create new filter
        updatedFilters.push(newFilterObject);
      } else {
        // update existing filter

        // have to create a new variable for this, bc. of typescript stuff  -.-
        const newUpdateValue = {
          ...updatedFilters[findIndex]?.values,
          ...newFilterObject.values,
        };

        if (findIndex !== -1) {
          const updatedFiltersIndex = updatedFilters[findIndex];
          if (updatedFiltersIndex) {
            updatedFiltersIndex.values = newUpdateValue;
          }
        }
      }

      return null;
    });

    setFilters(updatedFilters);
  };

  // event handler open Accordion. Decide if we want to delete a filter or open the accordion
  const handleChangeSelectedFilter = (panel: string) => (x: SyntheticEvent) => {
    // eslint-disable-next-line
    //@ts-ignore
    if (["path", "svg"].includes(x.target.tagName)) {
      setFilters((filters) => {
        return filters.filter((filter) => filter.key !== panel);
      });

      // get field name. Can be a single field like device or multiple fields
      // like measuredFrom|measuredTo and reset the fields
      const { fieldid } = (x.target as HTMLElement).dataset;
      if (fieldid) {
        const fields = fieldid.split("|");
        fields.map((field) => resetField(field));
      }
    } else {
      changeSelectedFilter(panel);
    }
  };

  const clearForm = () => {
    Object.keys(filterRules).map((field) => resetField(field));
  };

  const handleOnClearAll = () => {
    changeSelectedFilter(null);
    setFilters([]);
    clearForm();
    closeDialog();
  };

  const getFilterField = (fieldname: string): ChipData["values"] => {
    const filter = filters.filter((filter) => filter.type === fieldname);
    if (
      filter.length === 1 &&
      typeof filter[0] !== "undefined" &&
      "values" in filter[0]
    ) {
      return filter[0]?.values;
    }
    return { from: "", to: "", value: "" };
  };

  const swapFields = (
    firstField: string,
    secondField: string,
    firstRegistered: string,
    secondRegistered: string
  ) => {
    setValue(firstField, secondRegistered);
    const tempValue = firstRegistered;
    setValue(secondField, tempValue);
    clearErrors(firstField);
    clearErrors(secondField);
  };

  useEffect(() => {
    // set default value for the react hook form that we need for the
    resetForm();
    if (resetFormFields) {
      setResetFormFields(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resetFormFields]);

  const renderAccordions = (): ReactNode => {
    const ret = Object.entries(filterRules).map(([id, filterObject]) => {
      const { rules, inputProps, inputType } = filterObject;
      const hasRange = id.endsWith("From");

      if (id.endsWith("To")) return null;

      // eslint-disable-next-line no-param-reassign
      const cleanId = cleanKey(id);
      const idTo = `${cleanId}To` as unknown as keyof typeof filterRules;
      const toObject = filterRules[idTo];

      return (
        <StyledAccordion
          key={cleanId}
          expanded={selectedFilter.includes(cleanId)}
          onChange={handleChangeSelectedFilter(cleanId)}
          disableGutters
        >
          <StyledAccordionSummary
            expandIcon={
              <AddIcon
                data-fieldid={id.endsWith("From") ? `${id}|${idTo}` : cleanId}
              />
            }
            aria-controls={`panel${id}-content`}
            id={`panel${id}-content`}
          >
            {t(`filterModal.filterOptions.${cleanId}`)}
          </StyledAccordionSummary>
          <StyledAccordionDetails>
            {!hasRange && inputType !== "select" && (
              <StyledTextField
                error={!!errors[cleanId]}
                placeholder={
                  t("filterModal.filterPlaceholder.enterDeviceID") as string
                }
                variant="outlined"
                defaultValue={getFilterField(cleanId).value}
                helperText={renderHelperText(errors, cleanId)}
                {...register(cleanId, rules)}
              />
            )}
            {!hasRange && inputType === "select" && (
              <StyledSelect
                variant="outlined"
                value={fieldWatcher[cleanId as keyof typeof fieldWatcher]}
                displayEmpty
                {...register(cleanId, {
                  onChange: (e) => setValue(cleanId, e.target.value),
                })}
              >
                {filterProducts.map(({ name, value }, index) => (
                  <MenuItem value={value} key={index}>
                    {name}
                  </MenuItem>
                ))}
              </StyledSelect>
            )}
            {hasRange && (
              <StyledFlexContainer>
                {inputType === "datetime" && (
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <Controller
                      name={`${cleanId}From`}
                      rules={{
                        validate: () => {
                          return validateEndDateValid(
                            fieldWatcher[id as keyof typeof fieldWatcher],
                            fieldWatcher[idTo as keyof typeof fieldWatcher]
                          );
                        },
                      }}
                      control={control}
                      defaultValue={null}
                      render={({ field: { onChange, value } }) => (
                        <DateTimePicker
                          value={value}
                          onChange={onChange}
                          format={t("dateTime.dateTimeFormat") as string}
                          label=""
                          slotProps={{
                            textField: {
                              error: id in errors,
                              helperText: renderHelperText(errors, id),
                            },
                          }}
                        />
                      )}
                    />
                  </LocalizationProvider>
                )}
                {inputType !== "datetime" && (
                  <StyledTextField
                    error={!!errors[cleanId]}
                    variant="outlined"
                    type={inputType}
                    defaultValue={
                      fieldWatcher[cleanId as keyof typeof fieldWatcher]
                    }
                    InputProps={{
                      inputProps,
                    }}
                    helperText={renderHelperText(errors, id)}
                    {...register(`${cleanId}From`, {
                      ...rules,
                      validate: () => {
                        return validateMinMaxValues(
                          fieldWatcher[id as keyof typeof fieldWatcher],
                          fieldWatcher[idTo as keyof typeof fieldWatcher]
                        );
                      },
                    })}
                  />
                )}
                <StyledDivider>—</StyledDivider>
                {inputType === "datetime" && (
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <Controller
                      name={idTo}
                      rules={rules}
                      control={control}
                      defaultValue={
                        fieldWatcher[idTo as keyof typeof fieldWatcher]
                      }
                      render={({ field: { onChange, value } }) => (
                        <DateTimePicker
                          value={value}
                          onChange={onChange}
                          label=""
                          format={t("dateTime.dateTimeFormat") as string}
                          slotProps={{
                            textField: {
                              error: !!errors[idTo],
                              helperText: errors[id] && (
                                <SwapButton
                                  onClickCallback={() =>
                                    swapFields(
                                      id,
                                      idTo,
                                      fieldWatcher[
                                        id as keyof typeof fieldWatcher
                                      ],
                                      fieldWatcher[
                                        idTo as keyof typeof fieldWatcher
                                      ]
                                    )
                                  }
                                />
                              ),
                            },
                          }}
                        />
                      )}
                    />
                  </LocalizationProvider>
                )}

                {inputType !== "datetime" && (
                  <StyledTextField
                    error={!!errors[cleanId]}
                    variant="outlined"
                    type={
                      toObject && "inputType" in toObject
                        ? toObject.inputType
                        : inputType
                    }
                    defaultValue={
                      fieldWatcher[cleanId as keyof typeof fieldWatcher]
                    }
                    InputProps={
                      toObject && "inputProps" in toObject
                        ? { inputProps: toObject.inputProps }
                        : {}
                    }
                    helperText={
                      errors[idTo]
                        ? renderHelperText(errors, idTo)
                        : errors[id] && (
                            <SwapButton
                              onClickCallback={() =>
                                swapFields(
                                  id,
                                  idTo,
                                  fieldWatcher[id as keyof typeof fieldWatcher],
                                  fieldWatcher[
                                    idTo as keyof typeof fieldWatcher
                                  ]
                                )
                              }
                            />
                          )
                    }
                    {...register(idTo, rules)}
                  />
                )}
              </StyledFlexContainer>
            )}
          </StyledAccordionDetails>
        </StyledAccordion>
      );
    });
    return ret;
  };

  return (
    <Dialog fullWidth maxWidth="tablet" open={isOpen} onClose={closeDialog}>
      <form onSubmit={handleSubmit(handleOnSubmit)}>
        <DialogTitle>{t("filterModal.modalHeadline")}</DialogTitle>
        <DialogContent>{renderAccordions()}</DialogContent>
        <DialogActions>
          {filters.length > 0 && (
            <Button variant="outlined" onClick={handleOnClearAll}>
              {t("filterModal.modalActionButtons.clearAll")}
            </Button>
          )}
          <Button type="submit">
            {t("filterModal.modalActionButtons.apply")}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};
