import i18next from "../i18n/i18n";

type NavigationItem = {
  path: string;
  title: string;
};

const allNavigationItems: NavigationItem[] = [
  {
    path: "/home",
    title: i18next.t("navItems.home"),
  },
  {
    path: "/login",
    title: i18next.t("navItems.signin"),
  },
  {
    path: "/measurements",
    title: i18next.t("navItems.measurements"),
  },
  {
    path: "/devices",
    title: i18next.t("navItems.devices"),
  },
  {
    path: "/subscriptions",
    title: i18next.t("navItems.subscriptions"),
  },
  {
    path: "/users",
    title: i18next.t("navItems.users"),
  },
];

const UnauthenticatedNavigationItems: NavigationItem[] =
  allNavigationItems.filter((item) => ["Sign in"].includes(item.title));

const adminNavigationItems: NavigationItem[] = allNavigationItems.filter(
  (item) => ["Home", "Measurements", "Users"].includes(item.title)
);

// Define the navigation items for an admin user
const customerNavigationItems: NavigationItem[] = allNavigationItems.filter(
  (item) => ["Home", "Measurements"].includes(item.title)
);

export {
  customerNavigationItems,
  adminNavigationItems,
  UnauthenticatedNavigationItems,
};
