import { useEffect, useState } from "react";
import { format, parseISO } from "date-fns";
import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useAtom } from "jotai";
import { atomWithStorage } from "jotai/utils";
import { LoadingSpinner } from "se-libcore/components";
import { FilterFactory, SortDirection } from "se-libcore/filters";
import {
  emailSearchAtom,
  measurementFilterAtoms,
  measurementSortingAtoms,
} from "../MeasurementFilter.atom";
import Table from "../../../shared/components/Table/Table";
import { MeasurementDataRow } from "./MeasurementTable.types";
import MeasurementDesktopHeader from "./MeasurementDesktopHeader";
import MeasurementDesktopRow from "./MeasurementDesktopRow";
import MeasurementMobileRow from "./MeasurementMobileRow";
import { useMeasurements } from "../../../hooks/useMeasurements";

interface DataItem {
  modelName: string;
  version: number;
  sampleProperty: string;
  renderedValue: string;
}

interface MeasurementTableProps {
  onCheckboxChange: (data: MeasurementDataRow, isChecked: boolean) => void;
  selectedItems: MeasurementDataRow[];
}

const pageAtom = atomWithStorage("measurementsPage", 0);
const rowsPerPageAtom = atomWithStorage("measurementsRowsPerPage", 20);

const MeasurementTable = ({
  onCheckboxChange,
  selectedItems,
}: MeasurementTableProps) => {
  const [pagination, setPagination] = useState({});
  // The zero-based index of the current page.
  const [page, setPage] = useAtom(pageAtom);
  const [rowsPerPage, setRowsPerPage] = useAtom(rowsPerPageAtom);
  const [sortBy, setSortBy] = useAtom(
    measurementSortingAtoms.measurementSortBy
  );
  const [sortDirection, setSortDirection] = useAtom(
    measurementSortingAtoms.measurementSortDirection
  );

  const { t } = useTranslation();

  const [measurementName] = useAtom(measurementFilterAtoms.measurementName);
  const [measurementFilters] = useAtom(
    measurementFilterAtoms.measurementFilters
  );
  const [emailFilters] = useAtom(emailSearchAtom);

  const formatDate = (date: string) => {
    return format(parseISO(date), t("screen.measurements.dateFormat"));
  };

  const onPageChange = (_: React.MouseEvent | null, page: number) => {
    setPage(page);
  };
  const onRowsPerPageChange = (e: React.ChangeEvent) => {
    // eslint-disable-next-line
    // @ts-ignore
    const newValue = e.target.value;
    setRowsPerPage(newValue);
    setPage(0);
    setPagination({ ...pagination, rowsPerPage: newValue });
  };

  const filterFactory = FilterFactory()
    .reset()
    .setPage(page)
    .setPerPage(rowsPerPage);

  if (measurementName) {
    filterFactory.appendMetadataFilter({
      type: "STRING_FILTER",
      value: measurementName,
      path: "name",
    });
  }

  // add the filters to the query as well
  if (measurementFilters) {
    measurementFilters.map((filter) => {
      if (["deviceSerialNumber"].includes(filter.key)) {
        filterFactory.appendNormalFilter({
          name: filter.key,
          value: filter.values.value || "",
        });
      } else if (["measuredAt"].includes(filter.key)) {
        if ("from" in filter.values) {
          const fromDate = format(
            filter.values.from as unknown as Date,
            t("dateTime.dateTimeFormatSeconds")
          );
          filterFactory.appendNormalFilter({
            name: "startTime",
            value: fromDate,
          });
        }
        if ("to" in filter.values) {
          const toDate = format(
            filter.values.to as unknown as Date,
            t("dateTime.dateTimeFormatSeconds")
          );
          filterFactory.appendNormalFilter({
            name: "endTime",
            value: toDate,
          });
        }
      } else {
        filterFactory.appendMetadataFilter({
          type: "STRING_FILTER",
          value: filter.values.value || "",
          path: filter.key,
        });
      }

      return filterFactory;
    });
  }

  if (emailFilters) {
    filterFactory.appendMetadataFilter({
      type: "EXACT_MATCH_FILTER",
      value: emailFilters,
      path: "measurerEmail",
    });
  }

  if (sortBy.path !== "") {
    filterFactory.appendSortBy(sortBy).setSortOrder(sortDirection);
  }

  const handleSort = (sortBy: string, sortOrder: SortDirection) => {
    const prefix = [
      "name",
      "productName",
      "thc",
      "cbd",
      "moisture",
      "waterActivity",
    ].includes(sortBy)
      ? "METADATA"
      : "BASE";

    setSortBy({ prefix, path: sortBy });
    setSortDirection(sortOrder);
  };

  const { data, status, error } = useMeasurements(filterFactory);

  const desktopRows: JSX.Element[] = [];
  const mobileRows: JSX.Element[] = [];
  const entities = data?.entities || [];

  entities.forEach((entity) => {
    const predictions = entity.predictions.reduce((acc, item: DataItem) => {
      const { renderedValue, sampleProperty } = item;
      acc[sampleProperty] = renderedValue;
      return acc;
    }, {} as Record<string, string>);

    const row = {
      key: `measurement-${entity.id}`,
      name: entity.metadata?.name ?? "",
      email: entity.metadata?.measurerEmail ?? "",
      deviceId: entity.deviceSerialNumber,
      time: formatDate(entity.createdOn),
      app: entity.metadata?.productName || "",
      thc: predictions.THC || "",
      cbd: predictions.CBD || "",
      moisture: predictions.moisture || "",
      waterActivity: predictions.waterActivity as unknown as number,
    };

    desktopRows.push(
      <MeasurementDesktopRow
        data={row}
        key={row.key}
        onCheckboxChange={onCheckboxChange}
      />
    );
    mobileRows.push(<MeasurementMobileRow data={row} key={row.key} />);
  });

  useEffect(() => {
    // MUI Table pagination
    // https://mui.com/material-ui/api/table-pagination/#TablePagination-prop-onRowsPerPageChange
    setPagination({
      count: data?.total,
      page,
      rowsPerPage,
      showFirstButton: true,
      showLastButton: true,
      onPageChange,
      onRowsPerPageChange,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.total, page, rowsPerPage]);

  return (
    <>
      {status === "loading" && <LoadingSpinner />}
      {status === "success" && (
        <>
          <Table
            desktopHeader={
              <MeasurementDesktopHeader
                hasSelectedRows={selectedItems.length > 0}
                onSort={handleSort}
              />
            }
            desktopRows={desktopRows}
            mobileRows={mobileRows}
            pagination={pagination}
            loading={false}
          />
          {desktopRows.length === 0 && (
            <Typography align="center" variant="body1">
              {t("screen.measurements.errors.noMeasurements")}
            </Typography>
          )}
        </>
      )}
      {!!error && (
        <Typography align="center" variant="body1">
          {error.getFormattedErrorMessage(t)}
        </Typography>
      )}
    </>
  );
};

export default MeasurementTable;
