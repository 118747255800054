import {
  CircularProgress,
  TableContainer as MuiTableContainer,
} from "@mui/material";
import styled from "styled-components";
import { TableDesktopStyles } from "./TableDesktop.styles";
import { TableMobileStyles } from "./TableMobile.styles";

const TableContainer = styled(MuiTableContainer)<{ $loading: boolean }>`
  position: relative;

  ${({ theme }) => theme.breakpoints.down("laptop")} {
    margin-inline: -1.25rem;
    width: calc(100% + 2.5rem);
  }

  ${TableDesktopStyles.Table},
  ${TableMobileStyles.Body} {
    transition: opacity 0.15s;
    opacity: ${({ $loading }) => ($loading ? 0.35 : 1)};
  }
`;

const LoadingIndicator = styled(CircularProgress)`
  position: absolute;
  z-index: 1;
  left: calc(50% - 20px);
  top: calc(50% - 20px);
`;

export const TableStyles = {
  LoadingIndicator,
  TableContainer,
};
