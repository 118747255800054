const version = (() => {
  if (process.env.NODE_ENV === "development") {
    return "local";
  }
  return process.env.REACT_APP_VERSION || "unknown";
})();
const commitHash = (() => {
  if (process.env.NODE_ENV === "development") {
    return "local";
  }
  return process.env.REACT_APP_COMMIT_HASH || "unknown";
})();

export const versionInfo = {
  version,
  commitHash,
};
