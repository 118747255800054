import styled from "styled-components";
import { Box } from "@mui/material";
import BannerBackground from "../../images/bannerbackground.png";

export const Banner = styled(Box)`
  background-image: url(${BannerBackground});
  background-size: cover;
  background-position: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 14.375rem;
  max-width: 60.5rem;
  width: 100%;
  border-radius: 0.25rem;
  gap: 1rem;

  ${({ theme }) => theme.breakpoints.up("laptop")} {
    flex-direction: row;
  }
`;

export const Device = styled.img`
  position: relative;
  display: none;
  top: -0.625rem;
  height: 19.375rem;

  ${({ theme }) => theme.breakpoints.up("laptop")} {
    display: block;
  }
`;
export const WelcomeBannerContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  ${({ theme }) => theme.breakpoints.up("laptop")} {
    margin-left: 0.625rem;
    align-items: end;
  }
`;

export const WelcomeBannerText = styled.h2`
  font-size: 1.25rem;
  font-weight: 700;
  line-height: 2.75rem;
  color: ${({ theme }) => theme.palette.background.default};
  margin-bottom: 0.5rem;
  margin-top: 0.5rem;

  ${({ theme }) => theme.breakpoints.up("laptop")} {
    font-size: 2.25rem;
  }
`;

export const SignInButton = styled.button`
  position: relative;
  width: 17.5rem;
  height: 2.875rem;
  padding: 0.5rem 1rem;
  border-radius: 0.25rem;
  gap: 0.625rem;
  background: ${({ theme }) => theme.palette.background.default};
  color: ${({ theme }) => theme.palette.primary.main};
  font-size: 1.25rem;
  border: none;
  cursor: pointer;
  font-weight: 700;
  margin-bottom: 0.5rem;
`;

export const WelcomeText = styled.div`
  font-size: 1rem;
  color: #ffffff;
  margin-top: 0.5rem;
  margin-bottom: 1rem;
  padding: 0 1rem;
  text-align: center;

  ${({ theme }) => theme.breakpoints.up("laptop")} {
    font-size: 1.25rem;
    align-items: end;
    padding: 0;
    text-align: left;
  }
`;
