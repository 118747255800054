import { Trans, useTranslation } from "react-i18next";
import { ListItemIcon, MenuItem } from "@mui/material";
import { AccountCircle, Logout } from "@mui/icons-material";
import NavigationButtons from "./NavigationButtons";
import DropdownMenu from "../shared/components/DropdownMenu";
import { NavigationMenuStyles } from "./NavigationMenuDesktop.styles";
import { NavigationMenuProps } from "./NavigationMenu.types";
import { useLogout } from "../shared/lib/auth/hooks/useLogout";
import { useIsAuthenticated } from "../shared/useIsAuthenticated";

const { Container, LoggedInAs, UserMenuToggleButton, ToggleLabel } =
  NavigationMenuStyles;

const NavigationMenuDesktop: (props: NavigationMenuProps) => JSX.Element = ({
  userName,
}) => {
  const { t } = useTranslation();
  const { handleLogout, isLoading } = useLogout();
  const isAuthenticated = useIsAuthenticated();

  return (
    <Container>
      <NavigationButtons />
      {isAuthenticated && (
        <DropdownMenu
          button={
            <UserMenuToggleButton>
              <AccountCircle role="presentation" />
              <ToggleLabel>{t("userMenu.toggleButtonLabel")}</ToggleLabel>
            </UserMenuToggleButton>
          }
        >
          <LoggedInAs>
            <Trans t={t} i18nKey="userMenu.loggedInAs" values={{ userName }} />
          </LoggedInAs>

          <MenuItem disabled={isLoading} onClick={handleLogout}>
            <ListItemIcon>
              <Logout />
            </ListItemIcon>
            {t("userMenu.logOut")}
          </MenuItem>
        </DropdownMenu>
      )}
    </Container>
  );
};

export default NavigationMenuDesktop;
