import { PaginatedUserResultSchema } from "se-libcore/users";
import { api } from "se-libcore/api";
import { FilterFactory } from "se-libcore/filters";
import { LogService } from "se-libcore/services";
import { useAsyncQuery } from "se-libcore/async";

import { authClient } from "../shared/lib/http/client";

const { getUsersList } = api.users;

export function useUsers(filterFactory: ReturnType<typeof FilterFactory>) {
  return useAsyncQuery({
    queryKey: ["users", filterFactory.getFilterParams()],
    queryFn: async () => {
      const response = await getUsersList(authClient, filterFactory);

      const result = PaginatedUserResultSchema.safeParse(response);

      if (!result.success) {
        LogService.error("users errors:", result.error.issues);
        throw new Error("Failed to parse users response");
      }

      return result.data;
    },
    cacheTime: 0,
  });
}
