import styled from "styled-components";
import { TextField as MuiTextField } from "@mui/material";

const TextFieldStyled = styled(MuiTextField)`
  width: 15rem;

  & .MuiOutlinedInput-root:has(input:not(:placeholder-shown)) {
    ${({ theme }) => `
      outline: 4px solid ${theme.palette.brandPurple.dark};
      .MuiOutlinedInput-notchedOutline {
        border-width: 2px;
        border-color: ${theme.palette.primary.main};
      }
      .MuiSvgIcon-root {
        color: ${theme.palette.primary.main};
      }
    `}
  }
`;

export const SearchFieldStyles = {
  TextFieldStyled,
};
