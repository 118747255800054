import { useEffect } from "react";
import { LogService } from "se-libcore/services";
import AppRouter from "./router/AppRouter";
import { versionInfo } from "./shared/versionInfo";

function App() {
  useEffect(() => {
    LogService.info(
      `Running se-prp-web in with version ${versionInfo.version} and commit ${versionInfo.commitHash}`
    );
  }, []);
  return <AppRouter />;
}

export default App;
