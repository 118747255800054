import styled from "styled-components";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Button,
  Select,
  TextField,
} from "@mui/material";

export const StyledFlexContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: top;
`;
export const StyledDivider = styled.span`
  padding: 0.6rem;
`;

export const StyledSwapButton = styled(Button)`
  padding: 0;
  font-weight: normal;
  font-size: 0.8rem;
  margin: 0;
  margin-top: 0.25rem;
`;

export const StyledAccordion = styled(Accordion)`
  border: 1px solid ${({ theme }) => theme.palette.brandGray.main};
  background-color: ${({ theme }) => theme.palette.brandGray.light};
  color: ${({ theme }) => theme.palette.primary.main};
  font-weight: bold;
  font-size: 1.125rem;
`;
export const StyledAccordionSummary = styled(AccordionSummary)`
  color: ${({ theme }) => theme.palette.primary.main};
  font-weight: bold;
  font-size: 1rem;
  &.Mui-expanded {
    color: ${({ theme }) => theme.palette.text.primary};
  }
  & .MuiAccordionSummary-expandIconWrapper.Mui-expanded {
    transform: rotate(45deg);
    z-index: 1;
  }
`;
export const StyledAccordionDetails = styled(AccordionDetails)`
  color: ${({ theme }) => theme.palette.text.primary};
`;
export const StyledSelect = styled(Select)`
  padding: 0.625rem;
  width: 100%;
  div {
    padding: 0 0.5rem;
  }
`;
export const StyledTextField = styled(TextField)`
  .MuiFormHelperText-root {
    margin-left: 0px;
    text-transform: capitalize;
    color: #b02323;
    display: inline-block;

    button {
      margin-top: 0;
    }
  }
`;
