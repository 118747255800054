import { api } from "se-libcore/api";
import { useAsyncQuery } from "se-libcore/async";
import { authClient } from "../shared/lib/http/client";

const { listProducts, getProductByName } = api.product;

export const useProducts = () => {
  return useAsyncQuery({
    queryKey: ["get-products"],
    queryFn: async () => {
      return listProducts(authClient);
    },
  });
};

export type GetProductsFnType = typeof listProducts;

export const useProductByName = (modelname: string) => {
  return useAsyncQuery({
    queryKey: ["get-products-by-name", modelname],
    queryFn: async () => {
      return getProductByName(authClient, modelname);
    },
  });
};
