import styled from "styled-components";
import { PageStyles } from "../../layout/Page.styles";
import AdminHomeScreen from "./WelcomeHomeScreen";

const { Body: OriginalBody } = PageStyles;

const StyledBody = styled(OriginalBody)`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const HomeScreen = () => {
  return (
    <StyledBody>
      <AdminHomeScreen />
    </StyledBody>
  );
};

export default HomeScreen;
