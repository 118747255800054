import { PaginatedMeasurementResultSchema } from "se-libcore/measurements";
import { api } from "se-libcore/api";
import { FilterFactory } from "se-libcore/filters";
import { LogService } from "se-libcore/services";

import { useAsyncQuery } from "se-libcore/async";
import { authClient } from "../shared/lib/http/client";

const { getUserMeasurements } = api.measurement;

export function useMeasurements(
  filterFactory: ReturnType<typeof FilterFactory>
) {
  return useAsyncQuery({
    queryKey: ["measurement-history", filterFactory.getFilterParams()],
    queryFn: async () => {
      const response = await getUserMeasurements(authClient, filterFactory);

      const result = PaginatedMeasurementResultSchema.safeParse(response);

      if (!result.success) {
        LogService.error("measurement-history errors:", result.error.issues);
        throw new Error("Failed to parse measurement-history response");
      }

      return result.data;
    },
  });
}
