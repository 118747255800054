import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import appRoutes from "./Routes";
import AppView from "../layout/AppView";
import AuthorizationCodeHandler from "../screens/auth/AuthorizationCodeHandler";
import ProtectedRoutes from "../shared/lib/auth/components/ProtectedRoutes";
import Login from "../screens/auth/Login";
import HomeScreen from "../screens/home/HomeScreen";
import AppFooter from "../layout/AppFooter";

const AppRouter = (): JSX.Element => {
  return (
    <BrowserRouter basename="/">
      <Routes>
        <Route element={<Navigate to="/home" replace />} path="/" />
        <Route
          path="/authorization-code"
          element={<AuthorizationCodeHandler />}
        />
        <Route path="/signin" element={<Login />} />
        <Route
          path="/home"
          element={
            <>
              <AppView>
                <HomeScreen />
              </AppView>
              <AppFooter />
            </>
          }
        />
        <Route path="/" element={<ProtectedRoutes />}>
          {appRoutes.map((route) => {
            const { path, screen } = route;
            return (
              <Route
                element={<AppView>{screen}</AppView>}
                key={path}
                path={path}
              />
            );
          })}
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default AppRouter;
