import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useAtom } from "jotai";
import { PageStyles } from "../../../layout/Page.styles";
import SearchField from "../../../shared/components/SearchField/SearchField";
import { useDebounce } from "../../../hooks/useDebounce";
import { emailSearchAtom } from "../MeasurementFilter.atom";

const { Toolbar } = PageStyles;

/**
 * TODO: Implement admin search field
 */
export const AdminMeasurementSearchField = () => {
  const { t } = useTranslation(undefined, { keyPrefix: "screen.measurements" });
  const [emailSearch, setEmailSearch] = useAtom(emailSearchAtom);
  const debouncedEmailSearch = useDebounce(emailSearch, 200);

  useEffect(() => {
    setEmailSearch(debouncedEmailSearch);
  }, [debouncedEmailSearch, setEmailSearch]);

  return (
    <Toolbar>
      <SearchField
        placeholder={t("searchByEmail")}
        value={emailSearch}
        onChange={(e) => setEmailSearch(e.target.value)}
      />
    </Toolbar>
  );
};
