import { Button } from "@mui/material";
import { visuallyHidden } from "@mui/utils";
import styled from "styled-components";

const Container = styled.nav`
  display: flex;

  .MuiButton-root {
    font-size: ${({ theme }) => theme.typography.h5.fontSize};
    opacity: 0.55;

    &.active {
      opacity: 1;
    }
  }
`;

const LoggedInAs = styled.div`
  padding: 0.5rem 1.25rem;
`;

const UserMenuToggleButton = styled(Button).attrs({
  variant: "text",
  color: "inherit",
})`
  min-width: 0;
`;

const ToggleLabel = styled.span.attrs({ style: visuallyHidden })``;

export const NavigationMenuStyles = {
  Container,
  LoggedInAs,
  UserMenuToggleButton,
  ToggleLabel,
};
