import styled, { css } from "styled-components";
import { ArrowForwardIosRounded } from "@mui/icons-material";
import { ButtonBase } from "@mui/material";

const Body = styled.div`
  display: flex;
  flex-direction: column;
`;

const rowBaseStyle = css`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 1.25rem;
  padding-inline: 1.25rem;
`;

const contentRowBaseStyle = css`
  ${rowBaseStyle}
  padding-block: 0.875rem;
  border: 0px solid ${({ theme }) => theme.palette.brandGray.main};

  & + &,
  &:first-child {
    border-top-width: 1px;
  }

  &:last-child {
    border-bottom-width: 1px;
  }
`;

const Header = styled.div`
  ${rowBaseStyle}
  background: ${({ theme }) => theme.palette.brandPurple.light};
  border-block: 1px solid ${({ theme }) => theme.palette.brandPurple.dark};
  font-size: ${({ theme }) => theme.typography.body2.fontSize};
  line-height: ${({ theme }) => theme.typography.body2.lineHeight};
  padding-block: 0.5rem;
`;

const Row = styled.div`
  ${contentRowBaseStyle}
`;

const RowButton = styled(ButtonBase)`
  ${contentRowBaseStyle}
  font: inherit;
  text-align: inherit;
`;

const NestedRow = styled.div`
  ${rowBaseStyle}
  padding-inline: 0;
`;

const Cell = styled.div<{ expand?: boolean }>`
  flex-grow: ${({ expand }) => (expand ? 1 : 0)};
`;

const Key = styled.div<{ expand?: boolean }>`
  flex-grow: ${({ expand }) => (expand ? 1 : 0)};
  font-weight: 700;
`;

const Value = styled.div<{ expand?: boolean }>`
  flex-grow: ${({ expand }) => (expand ? 1 : 0)};
  text-align: right;
`;

const Arrow = styled(ArrowForwardIosRounded)`
  font-size: inherit;
  margin: 0.25rem -0.15rem 0;
  align-self: center;
`;

export const TableMobileStyles = {
  Arrow,
  Body,
  Cell,
  Header,
  Key,
  NestedRow,
  Row,
  RowButton,
  Value,
};
