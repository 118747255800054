import { Navigate, Outlet, useLocation } from "react-router-dom";
import { LoadingSpinner } from "se-libcore/components";
import { useAuthResult } from "../hooks/useAuthResult";

/**
 * Only use this as a route parent for react-router v6 Routes
 */
const ProtectedRoutes: () => JSX.Element = () => {
  const { loading, user } = useAuthResult();
  const location = useLocation();

  if (loading) {
    return <LoadingSpinner />;
  }

  if (!user) {
    return <Navigate to="/home" state={{ from: location }} replace />;
  }

  return <Outlet />;
};

export default ProtectedRoutes;
