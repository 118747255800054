import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { Stack } from "@mui/material";
import { ArrowDropDown as MuiArrowDropDown } from "@mui/icons-material";

const SelectedItemDiv = styled.div<{ width?: string }>`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.palette.brandGray.light};
  color: ${({ theme }) => theme.palette.success.main};
  padding: 0.625rem;
  margin-right: 0.625rem;
  border-radius: 5px;
  position: relative;
  min-width: ${({ width }) => width || "190px"};
`;

const ArrowDropDown = styled(MuiArrowDropDown)<{ arrowposition?: string }>`
  position: absolute;
  bottom: -0.875rem; //-14px
  left: ${({ arrowposition }) => arrowposition || "15%"};
  transform: translateX(-50%);
  color: ${({ theme }) => theme.palette.brandGray.light};
`;

interface SelectedItemsIndicatorProps {
  count: number;
  selectedTextKey: string;
  translationPrefix: string;
  width?: string;
  arrowPosition?: string;
}

const SelectedItemsIndicator: (
  props: SelectedItemsIndicatorProps
) => JSX.Element = ({
  count,
  selectedTextKey,
  translationPrefix,
  width,
  arrowPosition,
}) => {
  const { t } = useTranslation(undefined, { keyPrefix: translationPrefix });

  return (
    <SelectedItemDiv width={width}>
      <Stack direction="row" spacing={0.5} alignItems="center">
        <span>{t(selectedTextKey, { count })}</span>
      </Stack>
      <ArrowDropDown arrowposition={arrowPosition} aria-hidden="true" />
    </SelectedItemDiv>
  );
};

export default SelectedItemsIndicator;
