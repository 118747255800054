import { useState } from "react";
import { useAtom } from "jotai";
import { useTranslation } from "react-i18next";
import CloseIcon from "@mui/icons-material/Close";
import { format } from "date-fns";
import { FilterDialog } from "./FilterDialog";
import { ChipData } from "./Filters.types";
import {
  FilterBarContainer,
  FilterBarInnerContainer,
  FilterBarLabel,
  StyledFilterChip,
  StyledFilterbarIcon,
  StyledClearButton,
} from "./FilterBar.styles";
import { measurementFilterAtoms } from "../MeasurementFilter.atom";

export const FilterBar: () => JSX.Element = () => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [clearForm, setClearForm] = useState<boolean>(false);

  const [selectedFilter, setSelectedFilter] = useState<string[]>([]);
  const [filters, setFilters] = useAtom(
    measurementFilterAtoms.measurementFilters
  );

  const addSelectedFilter = (value: string) => {
    setSelectedFilter((selectedFilter) => [...selectedFilter, value]);
  };

  const changeSelectedFilter = (value: string) => {
    if (selectedFilter.includes(value)) {
      const newSelectedFilter = selectedFilter.filter((item) => item !== value);
      setSelectedFilter(newSelectedFilter);
    } else {
      addSelectedFilter(value);
    }
  };

  const handleClick = (chip: ChipData) => {
    addSelectedFilter(chip.key);
    setIsOpen(true);
  };
  const handleDelete = (chipToDelete: ChipData) => {
    setFilters((chips: ChipData[]) =>
      chips.filter((chip) => chip.key !== chipToDelete.key)
    );
    setSelectedFilter([]);
  };

  const formatFilterLabel = (filterData: ChipData): string => {
    // Display values with % sign
    if (["thc", "cbd", "moisture"].includes(filterData.type)) {
      return `${filterData.values.from ? `${filterData.values.from}%` : ""} - ${
        filterData.values.to ? `${filterData.values.to}%` : ""
      }`;
    }
    // Display values without any sign
    if (["measuredAt", "waterActivity"].includes(filterData.type)) {
      // Format the date correct on output
      if (filterData.type === "measuredAt") {
        let ret = "";
        const dateTimeFormat = t("dateTime.dateTimeFormat");

        if (filterData.values.from) {
          ret += format(new Date(filterData.values.from), dateTimeFormat);
        }
        ret += " - ";
        if (filterData.values.to) {
          ret += format(new Date(filterData.values.to), dateTimeFormat);
        }
        return ret;
      }
      return `${filterData.values.from} - ${filterData.values.to}`;
    }

    // Display single values without any format
    if (["productName", "deviceSerialNumber"].includes(filterData.type)) {
      if (filterData.type === "deviceSerialNumber") {
        // Trim to length
        const trimLength = 20;
        return `${
          (filterData.values.value || "").length > trimLength
            ? `${filterData.values.value?.substring(0, trimLength)}...`
            : filterData.values.value
        }`;
      }
      return filterData.values.value || "";
    }

    // Fallback if we don't have any values (which should not happen)
    // but typescript expect a fallback return value
    return filterData.label;
  };

  return (
    <>
      <FilterBarContainer>
        <FilterBarInnerContainer>
          <FilterBarLabel onClick={() => setIsOpen(true)} disableRipple>
            <StyledFilterbarIcon />
            {t("filterModal.modalHeadline")}
          </FilterBarLabel>
          <div>
            {filters.map((filter) => {
              return (
                <StyledFilterChip
                  label={
                    <>
                      <div>{filter.label}</div>
                      <span
                        style={{
                          textTransform: "capitalize",
                          display: "inline-block",
                        }}
                      >
                        {formatFilterLabel(filter)}
                      </span>
                    </>
                  }
                  onClick={() => handleClick(filter)}
                  onDelete={() => handleDelete(filter)}
                  color="primary"
                  deleteIcon={<CloseIcon style={{ fontSize: "1rem" }} />}
                  key={filter.key}
                />
              );
            })}
          </div>
        </FilterBarInnerContainer>
        <div>
          {filters.length > 0 && (
            <StyledClearButton
              variant="text"
              size="small"
              disableRipple
              onClick={() => {
                setFilters([]);
                setClearForm(true);
              }}
            >
              {t("filterModal.clearFilters")} <CloseIcon />
            </StyledClearButton>
          )}
        </div>
      </FilterBarContainer>
      <FilterDialog
        isOpen={isOpen}
        closeDialog={() => setIsOpen(false)}
        selectedFilter={selectedFilter}
        changeSelectedFilter={(value) =>
          changeSelectedFilter(value as string) as void
        }
        resetFormFields={clearForm}
        setResetFormFields={(value) => setClearForm(value)}
      />
    </>
  );
};
