import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import german from "./german";
import english from "./english";

i18next
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    lng: "en",
    resources: {
      en: {
        translation: {
          ...english,
        },
      },
      de: {
        translation: {
          ...german,
        },
      },
    },
  });

export default i18next;
