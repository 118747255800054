import { NavLink } from "react-router-dom";
import { Button } from "@mui/material";
import {
  customerNavigationItems,
  adminNavigationItems,
  UnauthenticatedNavigationItems,
} from "./navigationItems";
import { useIsUserAdmin } from "../shared/useIsUserAdmin";
import { useIsAuthenticated } from "../shared/useIsAuthenticated";
import { useGetLoginUrl } from "../shared/lib/auth/hooks/useGetLoginUrl";

const NavigationButtons = () => {
  const isUserAdministrator = useIsUserAdmin();
  const isAuthenticated = useIsAuthenticated();
  const { data } = useGetLoginUrl();
  let itemsToRender;

  if (!isAuthenticated) {
    itemsToRender = UnauthenticatedNavigationItems;
  } else {
    itemsToRender = isUserAdministrator
      ? adminNavigationItems
      : customerNavigationItems;
  }

  const getPath = (path: string): string => {
    return (path.startsWith("/login") ? data?.url : path) || "";
  };

  return (
    <>
      {itemsToRender.map((item) => (
        <Button
          className="NavigationButton"
          color="inherit"
          component={NavLink}
          key={item.path}
          to={getPath(item.path)}
          variant="text"
        >
          {item.title}
        </Button>
      ))}
    </>
  );
};

export default NavigationButtons;
