import { ThemeOptions } from "@mui/material";
import { palette } from "./palette";

export const components: ThemeOptions["components"] = {
  MuiCssBaseline: {
    styleOverrides: {
      html: {
        minHeight: "100vh",
      },
      body: {
        minHeight: "100vh",
        overscrollBehavior: "none",
        "&.mobile-nav-open": {
          overflow: "hidden",
        },
      },
      "#root": {
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
      },
    },
  },
  MuiButton: {
    defaultProps: {
      disableElevation: true,
      fullWidth: false,
      size: "large",
      variant: "contained",
    },
    styleOverrides: {
      sizeSmall: {
        padding: "1px 10px",
      },
      sizeLarge: {
        padding: "8px 16px",
      },
      contained: {
        border: "1px solid transparent",
      },
      root: {
        borderRadius: 4,
        borderColor: palette.primary.main,
        fontSize: "1rem",
        fontWeight: 700,
        letterSpacing: 0,
        lineHeight: 1.5,
        padding: "4px 16px",
        textTransform: "none",
      },
    },
  },
  MuiOutlinedInput: {
    styleOverrides: {
      notchedOutline: {
        borderWidth: "1px",
        borderRadius: "4px",
        borderColor: "#999999",
      },
    },
  },
  MuiTextField: {
    defaultProps: {
      variant: "outlined",
      size: "small",
      fullWidth: true,
    },
  },
  MuiDialog: {
    styleOverrides: {
      paperFullScreen: {
        overscrollBehavior: "none",
      },
    },
  },
  MuiDialogTitle: {
    styleOverrides: {
      root: {
        fontSize: "1.5rem",
        lineHeight: "1.3",
        padding: "1rem 1.25rem 0.625rem",
      },
    },
  },
  MuiDialogContent: {
    styleOverrides: {
      root: {
        padding: "1.25rem",
        ".MuiDialogTitle-root + &&": {
          paddingTop: "5px",
        },
      },
    },
  },
  MuiDialogContentText: {
    styleOverrides: {
      root: {
        color: palette.text.primary,
      },
    },
  },
  MuiDialogActions: {
    styleOverrides: {
      root: {
        padding: "0 1.25rem 1.25rem",
        gap: "1rem",
        "& > :not(:first-of-type)": {
          marginLeft: 0,
        },
        // "& > *": {
        //   flex: "1 1 max-content",
        // },
      },
    },
  },
  MuiTabs: {
    styleOverrides: {
      root: {
        padding: "1.25rem",
      },
      indicator: {
        display: "none",
      },
    },
  },
  MuiTab: {
    styleOverrides: {
      root: {
        fontSize: "1rem",
        lineHeight: "1.5",
        minWidth: 0,
        maxWidth: "none",
        minHeight: "2.5rem",
        padding: "0.5rem 1rem",
        textTransform: "none",
        borderRadius: 2,
        color: palette.primary.main,
        "&.Mui-selected": {
          background: palette.brandPurple.main,
        },
      },
    },
  },
  MuiAccordion: {
    defaultProps: {
      square: true,
      disableGutters: true,
    },
    styleOverrides: {
      root: {
        background: palette.brandGray.light,
        boxShadow: "none",
        borderBlock: `1px solid ${palette.brandGray.main}`,
        transition: "background 0.15s",
        "& + &": {
          marginTop: "-1px",
        },
        "&:before": {
          display: "none",
        },
        "&.Mui-expanded": {
          background: palette.background.default,
        },
      },
    },
  },
  MuiAccordionSummary: {
    styleOverrides: {
      root: {
        padding: "0.875rem 1.25rem",
        ".MuiAccordionSummary-content": {
          margin: 0,
        },
      },
    },
  },
  MuiAccordionDetails: {
    styleOverrides: {
      root: {
        opacity: 0,
        padding: "0.3rem 1.25rem 1.25rem",
        transition: "opacity 0.15s",
        ".Mui-expanded &": {
          opacity: 1,
        },
      },
    },
  },
  MuiMenu: {
    styleOverrides: {
      root: {
        ".MuiList-root": {
          paddingBlock: "0.75rem",
        },
        ".MuiMenuItem-root + .MuiDivider-root": {
          marginBlock: "0.75rem",
        },
      },
    },
  },
  MuiMenuItem: {
    styleOverrides: {
      root: {
        minHeight: "auto",
        padding: "0.5rem 1.25rem",
      },
    },
  },
  MuiTableCell: {
    styleOverrides: {
      root: {
        fontSize: "inherit",
        lineHeight: "inherit",
        borderBottomColor: palette.brandGray.main,
      },
      paddingCheckbox: {
        padding: "0 0.375rem",
      },
      paddingNone: {
        padding: 0,
      },
      sizeSmall: {
        padding: "0.5rem 0.75rem",
      },
      sizeMedium: {
        padding: "0.75rem 1rem",
      },
      head: {
        borderBottomColor: palette.text.primary,
        fontWeight: 700,
        "&&": {
          color: palette.primary.main,
        },
      },
    },
  },
  MuiTablePagination: {
    styleOverrides: {
      selectLabel: {
        font: "inherit",
      },
      displayedRows: {
        font: "inherit",
      },
      root: {
        font: "inherit",
      },
    },
  },
};
