import { useContext } from "react";
import { AuthResult } from "se-libcore/auth";
import { AuthResultContext } from "../components/AuthProvider";

export const useAuthResult = (): AuthResult => {
  const auth = useContext(AuthResultContext);

  if (!auth) {
    throw new Error("Missing auth result context");
  }

  return auth;
};
