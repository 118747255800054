import { createTheme, responsiveFontSizes } from "@mui/material/styles";
import { typography } from "./typography";
import { palette } from "./palette";
import { components } from "./components";
import { breakpoints } from "./breakpoints";

const baseTheme = createTheme({
  breakpoints,
  components,
  palette,
  typography,
});

// Scale down headlines on small screens
const theme = responsiveFontSizes(baseTheme, {
  breakpoints: ["mobileSmall", "mobileLarge", "tablet", "laptop"],
  factor: 1.7,
  variants: ["h1", "h2", "h3", "h4", "h5"],
});

export default theme;
