import Empty from "./Empty";

const Decimal: (props: { value: number | undefined | null }) => JSX.Element = ({
  value,
}) => {
  if (value !== undefined && value !== null && !Number.isNaN(value)) {
    return value as unknown as JSX.Element;
  }

  return <Empty />;
};

export default Decimal;
