import Empty from "./Empty";

const Email: (props: { value: string | undefined | null }) => JSX.Element = ({
  value,
}) => {
  if (value !== undefined && value !== null && typeof value === "string") {
    const [user, domain] = value.split("@", 2);
    if (!domain) return value as unknown as JSX.Element;

    return (
      <>
        {user}
        <wbr />@{domain}
      </>
    );
  }

  return <Empty />;
};

export default Email;
