import {
  Grid,
  Typography,
  Box,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import styled from "styled-components";

const Detail = styled.div`
  display: flex;
  align-items: center;
  margin-top: 1rem;
  padding-left: 0;
`;

const Model = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 2rem;
`;

const Label = styled.span`
  flex-shrink: 0;
  margin-right: 1rem;
  min-width: 6rem;
  font-weight: bold;
`;

const Value = styled.span`
  flex-grow: 1;
`;

const BoldValue = styled.span`
  font-weight: bold;
`;

const GridItem = styled(Grid)`
  border-right: 1px solid ${({ theme }) => theme.palette.brandGray.main};
  padding: 0;
`;

const StyledDialogTitle = styled(DialogTitle)`
  margin: 2rem 2rem 1.5rem;
  padding: 0;
`;

const StyledDialogContent = styled(DialogContent)`
  padding: 0 2rem;
`;
const StyledDialogActions = styled(DialogActions)`
  padding: 2rem;
`;

const Title = styled(Typography)`
  font-weight: bold;
  font-size: 1.3rem;
`;

const Footer = styled(Box)`
  display: flex;
  margin: 2rem;
  justify-content: space-between;
`;

const ModalBox = styled(Box)`
  width: 60%;
  padding: 1rem;
  background: ${({ theme }) => theme.palette.background.default};
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-height: 90vh;
  overflow-y: auto;
`;

export const UserDetailsStyles = {
  Detail,
  Model,
  Label,
  Value,
  BoldValue,
  GridItem,
  StyledDialogTitle,
  StyledDialogContent,
  StyledDialogActions,
  Title,
  Footer,
  ModalBox,
};
