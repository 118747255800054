import { useTranslation } from "react-i18next";
import { StyledSwapButton } from "./FilterDialog.styles";

interface SwapButtonProps {
  onClickCallback: () => void;
}

export const SwapButton = ({ onClickCallback }: SwapButtonProps) => {
  const { t } = useTranslation(undefined, { keyPrefix: "filterModal" });

  const handleButtonClick = () => {
    if (onClickCallback) {
      onClickCallback();
    }
  };

  return (
    <StyledSwapButton
      variant="text"
      size="small"
      onClick={() => handleButtonClick()}
    >
      {t("swapValues")}
    </StyledSwapButton>
  );
};
