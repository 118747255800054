import { Search } from "@mui/icons-material";
import { InputAdornment } from "@mui/material";
import { useTranslation } from "react-i18next";
import { SearchFieldProps } from "./SearchField.types";
import { SearchFieldStyles } from "./SearchField.styles";

const { TextFieldStyled } = SearchFieldStyles;

const SearchField: (props: SearchFieldProps) => JSX.Element = ({
  placeholder,
  ...rest
}) => {
  const { t } = useTranslation();

  const inputPlaceholder =
    placeholder || (t("components.searchField.placeholder") as string);

  const searchProps = {
    ...rest.InputProps,
    startAdornment: (
      <InputAdornment position="start">
        <Search />
      </InputAdornment>
    ),
  };

  return (
    <TextFieldStyled
      fullWidth={false}
      InputProps={searchProps}
      placeholder={inputPlaceholder}
      type="search"
      {...rest}
    />
  );
};

export default SearchField;
