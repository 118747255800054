import styled from "styled-components";
import { Typography } from "@mui/material";

const Header = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 0.75rem 2rem;
  margin-bottom: 1.5rem;

  ${({ theme }) => theme.breakpoints.up("laptop")} {
    gap: 1.25rem 2rem;
    margin-bottom: 2.5rem;
  }
`;

const Title = styled(Typography).attrs({ variant: "h1" })``;

const Toolbar = styled.nav`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 1rem;
`;

const Body = styled.div`
  flex: 1 0 0;
`;

export const PageStyles = {
  Body,
  Header,
  Title,
  Toolbar,
};
