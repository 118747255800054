import { Trans, useTranslation } from "react-i18next";
import { Checkbox, MenuItem } from "@mui/material";
import { MoreHoriz } from "@mui/icons-material";
import { useState } from "react";
import { UserDataRow } from "./UserTable.types";
import { TableDesktopStyles } from "../../../shared/components/Table/TableDesktop.styles";
import { DateTime, Email } from "../../../shared/components/Formats";
import DropdownMenu from "../../../shared/components/DropdownMenu";
import UserDetails from "./UserDetails";
import ConfirmationModal from "../../../shared/components/ConfirmationModal/ConfirmationModal";

const { Tr, Td, Buttons, Button } = TableDesktopStyles;

const UserDesktopRow: (props: {
  data: UserDataRow;
  onCheckboxChange: (data: UserDataRow, isChecked: boolean) => void;
}) => JSX.Element = ({ data, onCheckboxChange }) => {
  const { t } = useTranslation();
  const [isUserDetailsModalOpen, setIsUserDetailsModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const handleDeleteConfirm = () => {
    // TODO: Implement delete functionality
    setIsDeleteModalOpen(false);
  };

  return (
    <>
      <Tr>
        {isUserDetailsModalOpen && (
          <UserDetails
            data={data}
            isOpen={isUserDetailsModalOpen}
            onClose={() => setIsUserDetailsModalOpen(false)}
          />
        )}
        <Td shrink padding="checkbox">
          <Checkbox
            onChange={(event) => onCheckboxChange(data, event.target.checked)}
          />
        </Td>

        <Td>
          <Email value={data.preferredUsername} />
        </Td>
        <Td>{data.partialId}</Td>
        <Td>{data.role}</Td>
        <Td>
          {data.state
            ? t("screen.users.table.active")
            : t("screen.users.table.inactive")}
        </Td>
        <Td>
          <DateTime value={data.createdOn} />
        </Td>
        <Td>
          <DateTime value={data.modifiedOn} />
        </Td>
        <Td shrink padding="none">
          <Buttons>
            <Button onClick={() => setIsUserDetailsModalOpen(true)}>
              {t("screen.users.table.details")}
            </Button>
            <DropdownMenu
              button={
                <Button>
                  <MoreHoriz />
                </Button>
              }
            >
              <MenuItem>{t("screen.users.table.forcePasswordReset")}</MenuItem>
              <MenuItem>{t("screen.users.table.deactivateUser")}</MenuItem>
              <MenuItem onClick={() => setIsDeleteModalOpen(true)}>
                {t("screen.users.table.deleteUserPermanently")}
              </MenuItem>
            </DropdownMenu>
          </Buttons>
        </Td>
      </Tr>
      <ConfirmationModal
        open={isDeleteModalOpen}
        onConfirm={handleDeleteConfirm}
        onCancel={() => setIsDeleteModalOpen(false)}
        title={t("modalConfirmation.deleteConfirmation.deleteUsersTitle")}
        message={
          <Trans
            t={t}
            i18nKey="modalConfirmation.deleteConfirmation.deleteUsersMessage"
          />
        }
        confirmButton={t("modalConfirmation.deleteConfirmation.confirmButton")}
        cancelButton={t("modalConfirmation.deleteConfirmation.cancelButton")}
      />
    </>
  );
};

export default UserDesktopRow;
