const english = {
  dateTime: {
    dateTimeFormat: "yyyy-MM-dd HH:mm",
    dateTimeFormatSeconds: "yyyy-MM-dd HH:mm:ss",
  },
  authentication: {
    login: {
      buttonLabel: "Login",
      cannotGetLoginUrl: "Cannot get the login url",
      backToLogin: "Back to login",
    },
  },
  navItems: {
    home: "Home",
    signin: "Sign in",
    measurements: "Measurements",
    devices: "Devices",
    subscriptions: "Subscriptions",
    users: "Users",
  },
  navControls: {
    mainMenuLabel: "Main menu",
    openMainMenuLabel: "Open main menu",
    closeMainMenuLabel: "Close main menu",
  },
  userMenu: {
    toggleButtonLabel: "User menu",
    loggedInAs: "Logged in as <strong>{{userName}}</strong>",
    logOut: "Log out",
  },
  filterModal: {
    modalHeadline: "Filter by",
    swapValues: "Swap values",
    clearFilters: "Clear filters",
    min: "Min",
    max: "Max",
    filterOptions: {
      deviceSerialNumber: "Device",
      measuredAt: "Time",
      productName: "Product",
      productNameLabels: {
        all: "All",
        flower: "Flower",
        distillate: "Distillate",
      },
      thc: "THC",
      cbd: "CBD",
      moisture: "Moisture",
      waterActivity: "Water activity",
    },
    filterPlaceholder: {
      enterDeviceID: "Enter device ID",
      start: "Start",
      end: "End",
      selectProduct: "Select product",
      min: "Min",
      max: "Max",
    },
    filterErrorMessages: {
      invalidDeviceID: "This Device ID does not contain valid characters",
      minValueSmallerthanMax: "Min value should be smaller than max value",
      startTimeBeforeEndTime: "Start time should be before end time",
      valueBetween: "{{field}} value should be between {{min}} and {{max}}",
    },

    modalActionButtons: {
      clearAll: "ClearAll",
      apply: "Apply",
    },
  },
  modalConfirmation: {
    deleteConfirmation: {
      deleteMeasurement: "Delete measurement",
      deleteMeasurementsMessage:
        "Are you sure you want to delete the selected measurements? Measurement results will be removed <strong>permanently</strong>.",
      confirmButton: "Delete",
      cancelButton: "Cancel",
      deleteUsersTitle: "Delete users",
      deleteUsersMessage:
        "Are you sure you want to delete the selected users? User Profiles, associated measurement data and all related information will be removed <strong>permanently</strong>.",
    },
  },
  screen: {
    home: {
      title: "Home",
      bodyPlaceholder: "Welcome to Purpl PRO Portal!",
    },
    welcomeHome: {
      welcomeBanner: "Welcome to Purpl PRO Portal!",
      signedIn: "You are signed in as",
      signIn: "Please sign in to manage your measurements.",
      signInButton: "Sign in",
      getDevice: "Get the device",
      getDeviceText:
        "Purchase the Purpl PRO Device in our shop or from one of our distributors.",
      shopNow: "Shop now",
      showDistributers: "Show distributers",
      installApp: "Install the app",
      installAppText:
        "Download the Purpl PRO Mobile app for your device and sign in.",
      connectAndMeasure: "Connect and measure",
      connectAndMeasureText:
        "Follow setup instructions in the app and enjoy easy lab-accurate measurements right away!",
      findoutMore: "Find out more about Purpl at",
      findoutMoreLink: "<strong>Purpl Scientific</strong>",
      helpDesk: "Having trouble? Contact",
      helpDeskLink: "<strong>Purpl Help Desk</strong>",
      purplShop: "Buy devices and accessories at",
      purplShopLink: "<strong>Purpl Shop</strong>",
      cannotGetSigninUrl: "Cannot get the Sign in url",
      downloadAppStore: "Download on the App Store",
      downloadPlayStore: "Download on the Google Play",
      purplScientific: "Purpl Scientific Inc.",
      privacyPolicy: "Privacy Policy",
      licenseAgreement: "License agreement",
      termsAndConditions: "Terms and Conditions",
      moreAboutPurpl: "More about Purpl",
      helpDeskAlt: "Help Desk",
      purplShopAlt: "Purpl Shop",
      qrCodeAlt: "QR Code",
    },
    measurements: {
      title: "Measurements",
      searchByEmail: "Search by email",
      searchByName: "Search by name",
      action: "Actions",
      measurementsSelected_one: "{{count}} measurement selected",
      measurementsSelected_other: "{{count}} measurements selected",
      deleteMeasurement: "Delete measurement",
      clearSelection: "Clear selection",
      dateFormat: "yyyy-MM-dd HH:mm",
      table: {
        name: "Name",
        user: "User",
        deviceId: "Device",
        deviceLabel: "Device {{deviceId}}",
        time: "Time",
        app: "App",
        thc: "THC",
        cbd: "CBD",
        moisture: "Moisture",
        waterActivity: "Water act.",
        details: "Details",
        deleteMeasurement: "Delete measurement",
      },
      measurementDetails: {
        title: "My Measurement",
        user: "User",
        time: "Time",
        app: "App",
        device: "Device",
        actions: "Actions",
        close: "Close",
        thc: "Total THC",
        cbd: "Total CBD",
        moisture: "Moisture",
        waterActivity: "Water Activity",
        deleteMeasurement: "Delete Measurement",
      },
      errors: {
        noMeasurements: "Could not find any measurements.",
      },
    },
    devices: {
      title: "Devices",
      bodyPlaceholder: "Here are your devices",
    },
    subscriptions: {
      title: "Subscriptions",
      bodyPlaceholder: "Subscriptions go here",
    },
    users: {
      title: "Users",
      bodyPlaceholder: "Users live here",
      searchByEmail: "Search by email",
      action: "Actions",
      usersSelected_one: "{{count}} user selected",
      usersSelected_other: "{{count}} users selected",
      forcePasswordReset: "Force password reset",
      reactivateUsers: "Reactivate users (if deactivated)",
      deactivateUsers: "Deactivate Users",
      delete: "Delete users permanently",
      clearSelection: "Clear selection",
      table: {
        userId: "ID",
        email: "Email",
        partialId: "Partial ID",
        role: "Role",
        state: "State",
        createdOn: "Created on",
        modifiedOn: "Updated on",
        details: "Details",
        forcePasswordReset: "Force password reset",
        deactivateUser: "Deactivate user",
        deleteUserPermanently: "Delete user permanently",
        active: "Active",
        inactive: "Inactive",
      },
      products: {
        name: "Name",
        status: "Status",
        details: "Details",
        startTrial: "Start trial",
        stopTrial: "Stop trial",
        changeTrialPeriod: "Change trial period",
      },
      trialDialog: {
        startProductTrial: "Start product trial",
        changeProductTrial: "Change trial period",
        stopProductTrial: "Stop trial",
        stopProductTrialText:
          "Stopping the trial will take immediate effect. The trial will be displayed as expired, and the user won’t be able to select the product or measure with it in the mobile app.",
        start: "Start trial",
        change: "Apply changes",
        stop: "Stop trial",
        cancel: "Cancel",
        currentPeriod: "Current period",
        daysRemaining: "{{days}} days remaining",
        product: "Product",
        user: "User",
        role: "Role",
        duration: "Duration",
        newDuration: "New duration",
        numberOfDays: "Number of days",
        days: "Days",
        untilDate: "Until date",
        endDate: "End date",
        enterDate: "Enter date",
      },
      userDetails: {
        title: "User profile of {{userName}}",
        coreDetails: "Core details",
        products: "Products",
        firstActivated:
          "First activated <date>{{activationDate}}</date> by {{activationUser}}",
        lastUpdated:
          "Last updated <date>{{updateDate}}</date> by {{updateUser}}",
        active: "Active",
        inactive: "Inactive",
        userId: "User ID",
        role: "Role",
        state: "State",
        source: "Source",
        modifiedOn: "Updated on",
        createdOn: "Created on",
        actions: "Actions",
        forcePasswordReset: "Force password reset",
        deletePermanently: "Delete user permanently",
        close: "Close",
      },
      errors: {
        internalBackendError: "Unknown server error",
        resetSearch: "Reset search settings",
      },
    },
  },
};

export default english;
