import Empty from "./Empty";

const DateTime: (props: {
  value: string | undefined | null;
}) => JSX.Element = ({ value }) => {
  const timestamp = Date.parse(value || "-");

  if (!Number.isNaN(timestamp)) {
    return value as unknown as JSX.Element;
  }

  return <Empty />;
};

export default DateTime;
