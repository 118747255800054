import React, { useEffect } from "react";
import { Dialog, DialogContent, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { LegalDocumentTypes } from "./LegalDocument.types";

interface LegalDocumentProps {
  open: boolean;
  handleClose: () => void;
  legalDocument: LegalDocumentTypes;
}

type CustomDivComponentProps = React.HTMLAttributes<HTMLDivElement> & {
  name: string;
};
// We are using this component to pass attributes to the div element like "name"
// which is not existent on the div. We can't use data attributes here
// bc. the name is expected by a thrird party script
// Maybe there is a better way to do this.
const CustomDiv: React.FC<CustomDivComponentProps> = ({ ...rest }) => {
  return <div {...rest} />;
};

const LegalDocument = ({
  open,
  handleClose,
  legalDocument,
}: LegalDocumentProps) => {
  useEffect(() => {
    // https://support.termly.io/en/articles/7904732-how-to-embed-a-policy-on-a-react-site
    const termlyScriptElement = document
      .getElementById("termly")
      ?.setAttribute("name", "termly-embed");
    const script = document.createElement("script");
    if (!termlyScriptElement) {
      script.src = "https://app.termly.io/embed-policy.min.js";
      script.setAttribute("id", "termly");
      script.async = true;
      document.body.appendChild(script);
    }
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  // The UUID for the legal documents
  const legalDocumentUUID = {
    termsAndConditions: "caa4ce79-0201-4f90-988c-919eccaeb065",
    privacyPolicy: "5f9f4d83-92da-43f1-b278-e0b156232c1e",
  };

  return (
    <Dialog fullWidth maxWidth="laptop" open={open} onClose={handleClose}>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent dividers sx={{ height: "100vh" }}>
        <CustomDiv
          name="termly-embed"
          data-id={legalDocumentUUID[legalDocument]}
          data-type="iframe"
        />
      </DialogContent>
    </Dialog>
  );
};

export default LegalDocument;
