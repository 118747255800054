import { cloneElement, useId, useState } from "react";
import { Menu, PopoverOrigin } from "@mui/material";

type DropdownMenuProps = {
  button: JSX.Element;
  children: JSX.Element | JSX.Element[];
  anchorOrigin?: PopoverOrigin | undefined;
};

const DropdownMenu: (props: DropdownMenuProps) => JSX.Element = ({
  button,
  children,
  anchorOrigin = { vertical: "bottom", horizontal: "left" },
}) => {
  const id = useId();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleContainerClick = (event: React.MouseEvent<HTMLDivElement>) => {
    const target = event.target as Element;
    // Auto-close only when non-disabled button is clicked
    if (
      target.classList.contains("MuiMenuItem-root") &&
      !target.classList.contains("Mui-disabled")
    ) {
      handleClose();
    }
  };

  const toggleButtonProps = {
    "aria-controls": open ? `dropdown-menu-${id}` : undefined,
    "aria-expanded": open ? "true" : undefined,
    "aria-haspopup": "true",
    id: `dropdown-toggle-button-${id}`,
    onClick: handleClick,
  };
  const toggleButton = cloneElement(button, toggleButtonProps);

  return (
    <>
      {toggleButton}
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={anchorOrigin}
        id={`dropdown-menu-${id}`}
        marginThreshold={10}
        MenuListProps={{
          "aria-labelledby": `dropdown-toggle-button-${id}`,
        }}
        onClick={handleContainerClick}
        onClose={handleClose}
        open={open}
      >
        {children}
      </Menu>
    </>
  );
};

export default DropdownMenu;
