import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Box } from "@mui/material";
import { Page } from "se-libcore/mobile/layout";
import {
  AccessControlConstants,
  clientId,
  loginRedirectUri,
} from "se-libcore/auth";
import { api } from "se-libcore/api";
import { LoadingSpinner } from "se-libcore/components";
import { LogService } from "se-libcore/services";
import { useAsyncMutation } from "se-libcore/async";
import { LoginBlock, LoginButton } from "./AuthorizationCodeHandler.styles";
import { azureB2CClient } from "../../shared/lib/http/client";
import { useAuthResult } from "../../shared/lib/auth/hooks/useAuthResult";

type Uri = Record<string, string>;

const apiCallExchangeCode = async (authCode: string) => {
  const payload = {
    authorizationCode: authCode,
    clientId,
    redirectUri: loginRedirectUri,
  };

  const credentials = await api.auth.authorize(azureB2CClient, payload);
  const { accessToken, refreshToken } = credentials;

  localStorage.setItem(AccessControlConstants.accessToken, accessToken);
  localStorage.setItem(AccessControlConstants.refreshToken, refreshToken);
};

const AuthorizationCodeHandler = (): JSX.Element => {
  const { user, refetchUser } = useAuthResult();
  const { t } = useTranslation(undefined, {
    keyPrefix: "authentication",
  });

  const { mutate: authorize, error } = useAsyncMutation({
    mutationFn: async (authCode: string | undefined) => {
      if (!authCode) {
        LogService.error("[access-control] Missing code from route param");
        return;
      }

      await apiCallExchangeCode(authCode);
      await refetchUser();
    },
    onError(error) {
      LogService.error(
        "[access-control] Error while trying to exchange token",
        error.getFormattedErrorMessage(t)
      );
    },
  });

  const errorMessage = error?.getFormattedErrorMessage?.(t);

  const navigate = useNavigate();

  useEffect(() => {
    const currentUrl = window.location.href;

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const parseQueryString = (accumulator: any, value: string) => {
      const [key, val] = value.split("=");
      return { ...accumulator, [key as string]: val };
    };

    const splitTargetPath = (targetPath: string) => {
      const [path, str] = targetPath.split("?");
      const params = str?.split("&").reduce<Uri>(parseQueryString, {});

      return { path, params };
    };

    const uri = splitTargetPath(currentUrl);

    if (uri.path?.includes("/authorization-code") && uri?.params?.code) {
      const { code } = uri.params;
      authorize(code);
    }
  }, [refetchUser, authorize]);

  useEffect(() => {
    const isLoggedIn = !!user;
    if (isLoggedIn) {
      navigate("/", { replace: true });
    }
  }, [user, navigate]);

  return (
    <Page>
      <LoginBlock>
        {!errorMessage && <LoadingSpinner fullscreen={false} />}
        {!!errorMessage && (
          <>
            <Box sx={{ color: "error.main" }}>Error: {errorMessage}</Box>
            <LoginButton
              variant="contained"
              onClick={() => {
                navigate("/", { replace: true });
              }}
            >
              {t("login.backToLogin")}
            </LoginButton>
          </>
        )}
      </LoginBlock>
    </Page>
  );
};

export default AuthorizationCodeHandler;
