import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Trans, useTranslation } from "react-i18next";
import { Checkbox, MenuItem } from "@mui/material";
import { MoreHoriz } from "@mui/icons-material";
import { MeasurementDataRow } from "./MeasurementTable.types";
import { TableDesktopStyles } from "../../../shared/components/Table/TableDesktop.styles";
import {
  DateTime,
  Decimal,
  Percentage,
} from "../../../shared/components/Formats";
import DropdownMenu from "../../../shared/components/DropdownMenu";
import MeasurementDetails from "./MeasurementDetails";
import { useIsUserAdmin } from "../../../shared/useIsUserAdmin";
import ConfirmationModal from "../../../shared/components/ConfirmationModal/ConfirmationModal";
import { useDeleteMeasurement } from "../../../hooks/useDeleteMeasurement";

const { Tr, Td, Buttons, Button } = TableDesktopStyles;

const MeasurementDesktopRow: (props: {
  data: MeasurementDataRow;
  onCheckboxChange: (data: MeasurementDataRow, isChecked: boolean) => void;
}) => JSX.Element = ({ data, onCheckboxChange }) => {
  const { t } = useTranslation();
  const [openModal, setOpenModal] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const { mutate: deleteMeasurement } = useDeleteMeasurement();
  const navigate = useNavigate();

  const isUserAdministrator = useIsUserAdmin();

  const handleDeleteConfirm = async () => {
    const params = {
      measurementId: data.key.replace("measurement-", ""),
      username: data.email,
    };
    await deleteMeasurement(params);
    navigate("/measurements");
  };

  return (
    <>
      <Tr>
        <MeasurementDetails
          data={data}
          isOpen={openModal}
          onClose={() => setOpenModal(false)}
          title={isUserAdministrator ? data.email : data.name}
        />
        <Td shrink padding="checkbox">
          <Checkbox
            onChange={(event) => onCheckboxChange(data, event.target.checked)}
          />
        </Td>
        <Td>{isUserAdministrator ? data.email : data.name}</Td>
        {isUserAdministrator && <Td>{data.deviceId}</Td>}
        <Td>
          <DateTime value={data.time} />
        </Td>
        <Td>{data.app}</Td>
        <Td highlight numeric>
          <Percentage value={data.thc} />
        </Td>
        <Td highlight numeric>
          <Percentage value={data.cbd} />
        </Td>
        <Td highlight numeric>
          <Percentage value={data.moisture} />
        </Td>
        <Td highlight numeric>
          <Decimal value={data.waterActivity} />
        </Td>
        <Td shrink padding="none">
          <Buttons>
            <Button onClick={() => setOpenModal(true)}>
              {t("screen.measurements.table.details")}
            </Button>
            <DropdownMenu
              button={
                <Button>
                  <MoreHoriz />
                </Button>
              }
            >
              <MenuItem onClick={() => setIsDeleteModalOpen(true)}>
                {t("screen.measurements.table.deleteMeasurement")}
              </MenuItem>
            </DropdownMenu>
          </Buttons>
        </Td>
      </Tr>
      <ConfirmationModal
        open={isDeleteModalOpen}
        onConfirm={handleDeleteConfirm}
        onCancel={() => setIsDeleteModalOpen(false)}
        title={t("modalConfirmation.deleteConfirmation.deleteMeasurement")}
        message={
          <Trans
            t={t}
            i18nKey="modalConfirmation.deleteConfirmation.deleteMeasurementsMessage"
          />
        }
        confirmButton={t("modalConfirmation.deleteConfirmation.confirmButton")}
        cancelButton={t("modalConfirmation.deleteConfirmation.cancelButton")}
      />
    </>
  );
};

export default MeasurementDesktopRow;
