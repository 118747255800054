const german = {
  navItems: {
    home: "Startseite",
    measurements: "Messungen",
    devices: "Geräte",
    subscriptions: "Abonnements",
    users: "Benutzer",
  },
  navControls: {
    mainMenuLabel: "Hauptmenü",
    openMainMenuLabel: "Hauptmenü öffnen",
    closeMainMenuLabel: "Hauptmenü schließen",
  },
  userMenu: {
    toggleButtonLabel: "Benutzermenü",
    loggedInAs: "Eingeloggt als <strong>{{userName}}</strong>",
    logOut: "Ausloggen",
  },
  screen: {
    home: {
      title: "Startseite",
      bodyPlaceholder: "Willkommen im Purpl PRO Portal!",
    },
    adminHome: {
      welcomeMessage: "Willkommen!",
      welcomeTextMessage:
        "Dies ist eine MVP-Landingpage für Administratoren mit vollständig statischem Inhalt (keine Übersicht über Daten oder andere dynamische Elemente). Hier können Sie beschreiben, welche Informationen innerhalb des Portals verwaltet werden können, und Links zu anderen Admin-Schnittstellen bereitstellen, die dem Benutzer zur Verfügung stehen (Shopify, Freshdesk, Hauptwebsite Wordpress, andere?). Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. ",
      webLink:
        "Verwalten Sie WordPress-Inhalte unter <strong>Purpl Wissenschaftlich</strong>",
      helpDeskLink:
        "Verwalten Sie Freshdesk unter <strong>Purpl Beratungsstelle</strong>",
      shopLink:
        "Verwalten Sie Shopify-Inhalte unter <strong>Purpl Geschäft</strong>",
    },
    measurements: {
      title: "Messungen",
      searchByEmail: "Suche mit Email",
      action: "Aktionen",
      measurementsSelected_one: "{{count}} Messung ausgewählt",
      measurementsSelected_other: "{{count}} Messungen ausgewählt",
      deleteMeasurements: "Messungen löschen",
      clearSelection: "klare Auswahl",
      deleteMeasurementsMessage:
        "Sind Sie sicher, dass Sie die ausgewählten Messungen löschen möchten? Messergebnisse werden <strong>dauerhaft</strong> entfernt.",
      confirmButton: "löschen",
      cancelButton: "stornieren",
      table: {
        userEmail: "Benutzer Email",
        deviceId: "Geräte ID",
        deviceLabel: "Geräte {{deviceId}}",
        time: "Zeit",
        app: "App",
        thc: "THC",
        cbd: "CBD",
        moisture: "Feuchtigheit",
        waterActivity: "Wasserakt.",
        details: "Einzelheiten",
        deleteMeasurement: "Messung löschen",
      },
    },
    devices: {
      title: "Geräte",
      bodyPlaceholder: "Hier werden Ihre Geräte angezeigt",
    },
    subscriptions: {
      title: "Abonnements",
      bodyPlaceholder: "Hier ist der Platz für Ihre Abonnements",
    },
    users: {
      title: "Benutzer",
      bodyPlaceholder: "Hier wohnen die Nutzer",
      searchByEmail: "Suche mit Email",
      searchByName: "Suche nach Name",
      action: "Aktionen",
      usersSelected_one: "{{count}} Benutzer ausgewählt",
      usersSelected_other: "{{count}} Benutzer ausgewählt",
      forcePasswordReset: "Passwort-Reset erzwingen",
      reactivateUsers: "Benutzer reaktivieren (falls deaktiviert)",
      deactivateUsers: "Benutzer deaktivieren",
      deleteUsers: "Benutzer dauerhaft löschen",
      clearSelection: "Klare Auswahl",
      deleteUsersTitle: "Benutzer löschen",
      deleteUsersMessage:
        "Sind Sie sicher, dass Sie die ausgewählten Benutzer löschen möchten? Benutzerprofile, zugehörige Messdaten und alle zugehörigen Informationen werden <strong>dauerhaft</strong> entfernt.",
      confirmButton: "löschen",
      cancelButton: "stornieren",
      table: {
        userId: "ID",
        email: "Email",
        name: "Name",
        partialId: "Teil-ID",
        role: "Rolle",
        state: "Zustand",
        createdOn: "Erstellt am",
        modifiedOn: "aktualisiert am",
        forcePasswordReset: "Passwort-Reset erzwingen",
        deactivateUser: "Benutzer deaktivieren",
        deleteUserPermanently: "Benutzer dauerhaft löschen",
      },
      userDetails: {
        title: "{{userName}} Benutzerprofil",
        coreDetails: "Kerndetails",
        activeModels: "Aktive Modelle",
        firstActivated:
          "Zuerst aktiviert <date>{{activationDate}}</date> von {{activationUser}}",
        lastUpdated:
          "Letzte Aktualisierung <date>{{updateDate}}</date> von {{updateUser}}",
        active: "Aktiv",
        inactive: "Inaktiv",
        userId: "Benutzer-ID",
        role: "Rolle",
        state: "Zustand",
        source: "Quelle",
        modifiedOn: "Aktualisiert am",
        createdOn: "Erstellt am",
        actions: "Aktionen",
        forcePasswordReset: "Passwort-Reset erzwingen",
        deletePermanently: "Benutzer dauerhaft löschen",
        close: "Schließen",
      },
    },
  },
};

export default german;
