import { useQueryClient } from "react-query";
import { api } from "se-libcore/api";
import { useAsyncMutation } from "se-libcore/async";
import { authClient } from "../shared/lib/http/client";

interface DeleteParameters {
  measurementId: string;
  username: string;
}

export function useDeleteMeasurement() {
  const queryClient = useQueryClient();

  return useAsyncMutation({
    mutationFn: async (params: DeleteParameters) => {
      const response = await api.measurement.deleteUserMeasurementById(
        authClient,
        params.measurementId,
        params.username
      );

      return response;
    },

    // On success, invalidate the measurement history query
    // to ensure we're not displaying outdated data.
    onSuccess: () => {
      queryClient.invalidateQueries("measurement-history");
    },
  });
}
