import {
  TablePagination,
  TablePaginationProps,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { TableProps } from "./Table.types";
import TableMobile from "./TableMobile";
import TableDesktop from "./TableDesktop";
import { TableStyles } from "./Table.styles";

const { LoadingIndicator, TableContainer } = TableStyles;

const Table: (props: TableProps) => JSX.Element = ({
  className = "",
  desktopHeader,
  desktopRows,
  loading = false,
  mobileRows,
  pagination,
}) => {
  const theme = useTheme();
  const showMobileTable = useMediaQuery(theme.breakpoints.down("laptop"));

  const paginationDefaults: TablePaginationProps = {
    count: 0,
    onPageChange: () => null,
    onRowsPerPageChange: () => null,
    page: 1,
    rowsPerPage: 20,
    rowsPerPageOptions: [10, 20, 50, 100, 200],
  };

  const paginationProps = { ...paginationDefaults, ...pagination };
  return (
    <TableContainer
      className={className}
      $loading={loading}
      aria-busy={loading}
    >
      {loading && <LoadingIndicator />}

      {showMobileTable ? (
        <TableMobile className="TableMobile" mobileRows={mobileRows} />
      ) : (
        <TableDesktop
          className="TableDesktop"
          desktopHeader={desktopHeader}
          desktopRows={desktopRows}
        />
      )}
      {pagination && <TablePagination component="div" {...paginationProps} />}
    </TableContainer>
  );
};

export default Table;
