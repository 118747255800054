import { useCallback, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { api } from "se-libcore/api";
import { useAsyncQuery } from "se-libcore/async";
import {
  AccessControlConstants,
  AuthResult,
  LogoutOptions,
} from "se-libcore/auth";

import { isRunningMockBackend, isTesting } from "se-libcore/helpers";
import { LogService } from "se-libcore/services";
import { authB2CClient, authClient } from "../../http/client";

export const useAuth = (): AuthResult => {
  const shouldBeMock = isRunningMockBackend || isTesting;
  const token = shouldBeMock
    ? "foobar123"
    : localStorage.getItem(AccessControlConstants.accessToken);

  const logout = useCallback((options?: LogoutOptions) => {
    localStorage.removeItem(AccessControlConstants.accessToken);
    localStorage.removeItem(AccessControlConstants.refreshToken);
    localStorage.removeItem(AccessControlConstants.userRoles);
    localStorage.removeItem(AccessControlConstants.userInfo);
    localStorage.removeItem(AccessControlConstants.userPermissions);

    if (options?.reloadWindow) window.location.reload();
  }, []);

  const { t } = useTranslation();

  const {
    isLoading,
    refetch,
    error,
    data: user,
  } = useAsyncQuery({
    queryKey: ["user", token],
    queryFn: () => api.auth.getUser(authB2CClient),

    enabled: !!token,
  });

  useEffect(() => {
    if (error) {
      LogService.error(
        `error getting user info: ${error.getFormattedErrorMessage(t)}`
      );

      localStorage.removeItem(AccessControlConstants.accessToken);
    }
  }, [error, t]);

  return useMemo(
    () => ({
      loading: isLoading,
      user: user?.username ? user : null,
      logout,
      client: authClient,
      refetchUser: refetch,
    }),
    [isLoading, user, logout, refetch]
  );
};
