import styled from "styled-components";
import { Divider } from "@mui/material";
import DropdownMenu from "../../../shared/components/DropdownMenu";
import DropdownButton from "../../../shared/components/DropdownButton";

export const Container = styled.div`
  display: flex;
  gap: 0.625rem;
  position: relative;
`;

export const StyledDropdownMenu = styled(DropdownMenu)`
  position: absolute;
`;

export const StyledDivider = styled(Divider)`
  margin-left: 0.625rem;
  margin-right: 0.625rem;
`;

// TODO: change the green color to hex color
export const GreenOutlinedButton = styled(DropdownButton)`
  && {
    border-color: #008000;
    color: #008000;

    .MuiButton-outlined,
    svg {
      color: #008000;
    }
  }
`;
