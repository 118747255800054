import React from "react";
import styled from "styled-components";
import {
  Button,
  Dialog as MuiDialog,
  DialogContent,
  DialogTitle,
  DialogActions,
} from "@mui/material";

interface ConfirmationModalProps {
  title: string;
  message: string | JSX.Element | null;
  onConfirm: () => void;
  onCancel: () => void;
  open: boolean;
  confirmButton: string;
  cancelButton: string;
}

const Dialog = styled(MuiDialog)`
  .MuiPaper-root {
    max-width: 25rem;
  }
`;

const ConfirmationModal = ({
  title,
  message,
  onConfirm,
  onCancel,
  open,
  confirmButton,
  cancelButton,
}: ConfirmationModalProps) => {
  return (
    <Dialog open={open} fullWidth onClick={onCancel}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>{message}</DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={onCancel}>
          {cancelButton}
        </Button>
        <Button onClick={onConfirm}>{confirmButton}</Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmationModal;
