import { Permission } from "./permission.types";

export const PurpleDeleteMeasurementKey: Permission = {
  operation: "delete",
  resource: {
    service: "purpl",
    type: "user-history",
    objectId: "*",
  },
};

export const PurplListAllUserMeasurementKey: Permission = {
  resource: {
    service: "purpl",
    type: "history",
    objectId: "*",
  },
  operation: "read" as const,
};
