import { useTranslation } from "react-i18next";
import { Checkbox } from "@mui/material";
import { UnfoldMore, ArrowDownward, ArrowUpward } from "@mui/icons-material";
import { useAtom } from "jotai";
import { SortDirection } from "se-libcore/filters";
import { measurementSortingAtoms } from "../MeasurementFilter.atom";
import { TableDesktopStyles } from "../../../shared/components/Table/TableDesktop.styles";
import { useIsUserAdmin } from "../../../shared/useIsUserAdmin";

const { Tr, Th, SortIconContainer, SortableTh, SortableDiv } =
  TableDesktopStyles;

interface MeasurementDesktopHeaderProps {
  hasSelectedRows: boolean;
  onSort: (sortBy: string, sortOrder: SortDirection) => void;
}

const MeasurementDesktopHeader = ({
  hasSelectedRows,
  onSort,
}: MeasurementDesktopHeaderProps) => {
  const { t } = useTranslation(undefined, {
    keyPrefix: "screen.measurements.table",
  });

  const isUserAdministrator = useIsUserAdmin();

  type Column = {
    label: string;
    name: string;
    numeric?: boolean;
    highlight?: boolean;
  };

  const columns: Column[] = [
    {
      label: isUserAdministrator ? "user" : "name",
      name: isUserAdministrator ? "measurerUsername" : "name",
      numeric: false,
      highlight: false,
    },
    ...(isUserAdministrator
      ? [
          {
            label: "deviceId",
            name: "deviceSerialNumber",
            numeric: false,
            highlight: false,
          },
        ]
      : []),
    { label: "time", name: "createdOn", numeric: false, highlight: false },
    { label: "app", name: "productName", numeric: false, highlight: false },
    { label: "thc", name: "thc", numeric: true, highlight: true },
    { label: "cbd", name: "cbd", numeric: true, highlight: true },
    { label: "moisture", name: "moisture", numeric: true, highlight: true },
    {
      label: "waterActivity",
      name: "waterActivity",
      numeric: true,
      highlight: true,
    },
    { label: "", name: "" },
    { label: "", name: "" },
  ];

  const [sortedColumn, setSortedColumn] = useAtom(
    measurementSortingAtoms.measurementSortBy
  );
  const [sortDirection, setSortDirection] = useAtom(
    measurementSortingAtoms.measurementSortDirection
  );

  const handleSort = (column: string) => {
    const newDirection =
      sortedColumn.path === column && sortDirection === SortDirection.ASC
        ? SortDirection.DSC
        : SortDirection.ASC;
    setSortedColumn({ ...sortedColumn, path: column });
    setSortDirection(newDirection);

    onSort(column, newDirection);
  };

  const renderSortIcon = (columnName: string) => (
    <SortIconContainer>
      {
        // eslint-disable-next-line no-nested-ternary
        sortedColumn.path !== columnName ? (
          <UnfoldMore className="defaultSortIcon" />
        ) : sortDirection === SortDirection.DSC ? (
          <ArrowDownward fontSize="small" />
        ) : (
          <ArrowUpward fontSize="small" />
        )
      }
    </SortIconContainer>
  );

  return (
    <Tr>
      <Th shrink padding="checkbox">
        <Checkbox indeterminate={hasSelectedRows} />
      </Th>
      {columns.map(({ name, label, numeric, highlight }, index) => (
        <SortableTh
          key={index}
          numeric={numeric}
          highlight={highlight}
          onClick={() => handleSort(name)}
        >
          <SortableDiv>
            {label !== "" && (
              <>
                {t(label)} {renderSortIcon(name)}
              </>
            )}
          </SortableDiv>
        </SortableTh>
      ))}
    </Tr>
  );
};
export default MeasurementDesktopHeader;
