import { getTime } from "date-fns";

export const validateEndDateValid = (
  refField1: string | Date,
  refField2: string | Date
): boolean => {
  // check if we have a start and end date value and compare them
  if (
    refField1 !== "" &&
    refField2 !== "" &&
    refField1 !== null &&
    refField2 !== null
  ) {
    if (refField1 instanceof Date || refField2 instanceof Date) {
      // get timestamp to compare
      const refField1Timestamp = getTime(refField1 as Date);
      const refField2Timestamp = getTime(refField2 as Date);
      return refField1Timestamp <= refField2Timestamp;
    }

    return (refField1 as unknown as Date) <= (refField2 as unknown as Date);
  }
  return true;
};

export const validateMinMaxValues = (
  refField1: string,
  refField2: string
): boolean => {
  // check if we have 2 field values and compare them
  if (refField1 !== "" && refField2 !== "") {
    return Number(refField1) <= Number(refField2);
  }
  return true;
};
